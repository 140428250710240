import { Component, Input, OnInit } from '@angular/core';

import { TEXT_COLORS } from '../../utils/const/tag-const';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnInit {
  defaultClass = 'mat-chip__container';
  public tagClass: string = '';
  @Input() tagText = '';

  ngOnInit(): void {
    this.tagClass = `${this.defaultClass} ${this._getColorClassByText(this.tagText)}`;
  }

  private _getColorClassByText(text: string): string {
    if (text && text.includes('#')) return TEXT_COLORS['hashtag'];
    return TEXT_COLORS[text] || TEXT_COLORS['default'];
  }
}
