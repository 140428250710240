<div class="footer">
  <div class="footer__categories">
    <div class="category category--contact">
      <h5 class="title">{{ 'FOOTER.CONTACT.TITLE' | translate }}</h5>
      <div class="category__items">
        <img src="/assets/Dudas_footer.svg" alt="heinet_logo" />
        <a class="item item--icon link" [href]="'tel:' + contactNumber">
          <mat-icon color="primary">phone</mat-icon>
          <h4>{{ contactNumber }}</h4>
        </a>
        <span class="item contact__timeTable">{{ 'FOOTER.CONTACT.TIME_OPEN' | translate }}</span>
      </div>
    </div>

    <!-- These outter divs allow the structure of grid to mantain shape even when showFields is false -->
    <div>
      <div class="category category--help" *ngIf="showFields">
        <h5 class="title">{{ 'FOOTER.HELP.TITLE' | translate }}</h5>
        <span class="item"
          ><a class="link" href="/customers-help">{{ 'FOOTER.HELP.QUESTIONS' | translate }}</a></span
        >
      </div>
    </div>

    <div>
      <div class="category category--opinion" *ngIf="showFields">
        <h5 class="title">{{ 'FOOTER.OPINION.TITLE' | translate }}</h5>
        <div class="category__items">
          <div class="item--icon icon--pointer" (click)="navigateCreateCase()">
            <img class="like__icon" src="/assets/images/footer-like.svg" alt="like" /><span class="item">{{
              'FOOTER.OPINION.LIKE' | translate
            }}</span>
          </div>
          <div class="item--icon icon--pointer" (click)="navigateCreateCase()">
            <img src="/assets/images/footer-dislike.svg" alt="dislike" class="like__icon" /><span class="item">{{
              'FOOTER.OPINION.DISLIKE' | translate
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="category category--legal">
      <h5 class="title">{{ 'FOOTER.LEGAL.TITLE' | translate }}</h5>
      <div class="category__items">
        <a class="item link" href="/legal/0"
          >{{ 'FOOTER.LEGAL.READ' | translate }} {{ 'FOOTER.LEGAL.LEGAL_NOTICE' | translate }}</a
        >
        <a class="item link" href="/legal/1">{{ 'FOOTER.LEGAL.LEGAL_POLICY' | translate }}</a>
        <a class="item link" href="/legal/2">{{ 'FOOTER.LEGAL.COOKIES_POLICY' | translate }}</a>
        <a class="item link" (click)="openCookiesPreferences()">{{ 'FOOTER.LEGAL.COOKIES_CONFIG' | translate }}</a>
      </div>
    </div>
  </div>

  <div class="footer_secondSection">
    <div class="footer_secondSection_header">
      <img src="/assets/heinet_logo_footer.svg" alt="heinet_logo" />
      <span>{{ 'FOOTER.QUESTIONS.FOOTER_NEW_PORTAL' | translate }}</span>
    </div>
    <p class="footer_secondSection_content">
      {{ 'FOOTER.QUESTIONS.LEGAL_INFO' | translate }}
    </p>
  </div>
  <div class="footer_star">
    <img src="assets/images/footer-star.svg" alt="footer-star" />
  </div>
</div>
