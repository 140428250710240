<div class="sheet__container">
  <app-image-cropper [src]="imgBase64" (imgCaptured)="setImgCaptured($event)" *ngIf="imgcropper"></app-image-cropper>
  <div class="uploadImage_container">
    <h3>{{ 'PROFILE.UPLOAD_IMAGE.TITLE' | translate }}</h3>
    <form (ngSubmit)="uploadImage()" #imageForm="ngForm">
      <input class="uploadFile" type="file" accept="image/*" (change)="onFileSelected($event)" #fileInput />
      <p class="error-message" *ngIf="errorMessage">{{ errorMessage }}</p>
      <app-button [buttonConfig]="buttonConfigs.caseButton" type="submit" [disabled]="!isFormValid()">{{
        'PROFILE.UPLOAD_IMAGE.UPLOAD_BUTTON' | translate
      }}</app-button>
    </form>
  </div>
</div>
