<div class="navbar" [ngStyle]="{ height: !isMenuOpen ? '100vh' : '5.25rem' }">
  <nav md-tab-nav-bar aria-label="weather navigation links" class="nav">
    <div class="nav__logo">
      <mat-icon class="menu" (click)="toggleMenu()">{{ isMenuOpen ? 'menu' : 'close' }}</mat-icon>
      <a (click)="navigateAtHome()">
        <img class="logo__img" src="./assets/images/logo_Heinet.svg" alt="Heinet-logo" />
      </a>
    </div>

    <ul class="nav__items nav__items__list" [id]="isMenuOpen ? 'ocultar' : ''">
      <ng-container *ngFor="let navItem of navItems">
        <li
          *hasPermission="navItem.url"
          (click)="closeMenu()"
          class="nav__item"
          [ngClass]="navItem.url === activeNavItem ? 'nav__item--active' : ''"
        >
          <a class="link" *hasPermission="navItem.url" routerLinkActive="active" (click)="setActiveNavItem(navItem)"
            >{{ navItem.name }}<mat-icon *ngIf="navItem.icon !== ''">{{ navItem.icon }}</mat-icon></a
          >
        </li>
      </ng-container>
      <div
        (click)="closeMenu()"
        class="profile__container__mobile"
        routerLinkActive="active"
        (click)="setIndexProfileResponsive(profileTabIndex.USER_DATA)"
        [class.nav__item--active]="activeNavItem === 'profile'"
      >
        <a>
          <div class="profile__container__all">
            <div class="profile__container__mobile__content">
              <img *ngIf="existProfileImage" class="avatar__img" [src]="profileImage" alt="profile-image" />
              <div class="avatar__initials">{{ nameInitials() }}</div>
            </div>
            <div class="link__text">{{ getProfileNameResponsive() }}</div>
          </div>
        </a>
      </div>
      <div class="close__session__mobile" (click)="showDialog()">
        {{ 'HEADER.SUBMENU.CLOSE_SESSION' | translate }}
      </div>
    </ul>

    <div class="nav__items user__management" [style.display]="!isMenuOpen ? 'none' : ''">
      <ul>
        <li
          *ngFor="let navItem of profileItems"
          class="nav__item"
          [ngClass]="navItem.url === activeNavItem ? 'nav__item--active' : ''"
          [class.nav__item--profile]="navItem.url === 'profile'"
        >
          <!-- Alerts -->
          <div *ngIf="navItem.url !== 'profile'">
            <a class="link" *hasPermission="navItem.url" routerLinkActive="active" (click)="setActiveNavItem(navItem)">
              <mat-icon *ngIf="navItem.url === 'alerts' && !unreadAlerts" [svgIcon]="'ico-bell'" />
              <!-- Only add badge if unreadAlerts -->
              <mat-icon
                *ngIf="navItem.url === 'alerts' && unreadAlerts"
                [matBadge]="unreadAlerts"
                matBadgeSize="medium"
                matBadgeColor="warn"
                [svgIcon]="'ico-bell'"
              />
              <mat-icon *ngIf="navItem.url === 'cases'" [svgIcon]="'ico-headphones'" />
              <p [ngClass]="navItem.icon ? 'link__text' : ''">{{ navItem.name }}</p>
            </a>
          </div>

          <div *ngIf="navItem.url === 'profile'" mat-button [matMenuTriggerFor]="menu" class="inactive__profile">
            <a class="link" *hasPermission="navItem.url">
              <div class="profile__avatar">
                <div class="avatar__initials">{{ nameInitials() }}</div>
                <img *ngIf="existProfileImage" class="avatar__img" [src]="profileImage" alt="profile-image" />
              </div>

              <p class="link__text">{{ navItem.name }}</p>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="setIndexProfile(navItem, profileTabIndex.USER_DATA)">
              {{ 'HEADER.SUBMENU.USER_DATA' | translate }}
            </button>
            <button mat-menu-item (click)="setIndexProfile(navItem, profileTabIndex.COMPANY_DETAILS)">
              {{ 'HEADER.SUBMENU.BUSSINES_DATA' | translate }}
            </button>
            <div *ngFor="let navItem of userManagementItems">
              <button
                *hasPermission="navItem.hasPermission!"
                mat-menu-item
                (click)="setIndexProfile(navItem, profileTabIndex.USER_ADMINISTRATION)"
              >
                {{ 'HEADER.SUBMENU.USER_MANAGEMENT' | translate }}
              </button>
            </div>
            <hr />
            <button mat-menu-item (click)="showDialog()" class="close__session">
              {{ 'HEADER.SUBMENU.CLOSE_SESSION' | translate }}
            </button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </nav>
</div>
