import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateModule } from '@ngx-translate/core';

import { DirectivesModule } from '../directives/directives.module';
import { MaterialModule } from '../modules/material.module';

// Components
import { AttachmentComponent } from './attachment/attachment.component';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';
import { ButtonComponent } from './button/button.component';
import { CardComponent } from './card/card.component';
import { CarouselComponent } from './carousel/carousel.component';
import { DialogComponent } from './dialog/dialog.component';
import { DynamicFieldComponent } from './dynamic-field/dynamic-field.component';
import { DynamicErrorComponent } from './dynamic-form/dynamic-error/dynamic-error.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { CustomMatPaginatorIntl } from './table/customMatPaginatorIntl';
import { TableComponent } from './table/table.component';
import { AlertComponent } from './warning/warning.component';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AlertsComponent } from './alerts/alerts.component';
import { DividerComponent } from './divider/divider.component';
import {
  CheckboxFieldComponent,
  DateFieldComponent,
  InputFieldComponent,
  RadioFieldComponent,
  SelectFieldComponent,
  SliderFieldComponent,
  TextareaFieldComponent,
} from './dynamic-field/field-types/index';
import { EmptyDataComponent } from './empty-data/empty-data.component';
import { HeinekenToolsListComponent } from './heineken-tools-list/heineken-tools-list.component';
import { IframeComponent } from './iframe/iframe.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { SearchComponent } from './search/search.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TableSkeletonComponent } from './table-skeleton/table-skeleton.component';
import { TagComponent } from './tag/tag.component';

/* añadir los componetes a este array */
const sharedComponents = [
  ButtonComponent,
  EmptyDataComponent,
  HeaderComponent,
  FooterComponent,
  SidenavComponent,
  DialogComponent,
  TableComponent,
  CardComponent,
  AttachmentComponent,
  DynamicFormComponent,
  DynamicFieldComponent,
  DynamicErrorComponent,
  InputFieldComponent,
  CheckboxFieldComponent,
  RadioFieldComponent,
  TextareaFieldComponent,
  SelectFieldComponent,
  SliderFieldComponent,
  DateFieldComponent,
  CarouselComponent,
  AlertComponent,
  HeinekenToolsListComponent,
  AlertsComponent,
  IframeComponent,
  SearchComponent,
  TagComponent,
  TableSkeletonComponent,
  DividerComponent,
  ImageCropperComponent,
  BottomSheetComponent,
  SpinnerComponent,
];
@NgModule({
  declarations: [...sharedComponents],
  exports: [...sharedComponents],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'This item is actually loading...' }),
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
  ],
})
export class ComponentsModule {}
