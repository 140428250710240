import { Component, Input } from '@angular/core';

import { DirectusService } from '../../../core/services/directus.service';

import { environment } from '../../../../environments/environment';
import { IAttachment } from '../../interfaces/article.interface';
import { DownloadFileService } from '../../utils/downloadFile/download-file.service';
import { ExtensionTypes } from '../../utils/enums/attachmentFile.model';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent {
  @Input() fileId!: string;
  public fileURL = '';
  public fileData!: IAttachment;
  public defaultPlaceholder = '';

  private readonly _imageURL = environment.directus.assetsUrl;
  private readonly _megaByteRatio = 1000000; // Byte to MegaByte

  constructor(private _directus: DirectusService, private _downloadFileService: DownloadFileService) {}

  ngOnInit() {
    // Retrieve file data based on the id of the attachment
    this._retrieveFileData();
  }

  private _retrieveFileData(): void {
    this._directus.getFileById(this.fileId).subscribe((response: IAttachment) => {
      this.fileData = response;
      this._formatData();
    });
  }

  /**
   * @description this function formats file data for better visualization
   */
  private _formatData(): void {
    // File size to MB
    let size: number = parseInt(this.fileData.filesize);
    size /= this._megaByteRatio;
    this.fileData.filesize = size.toFixed(2).toString();

    // File type
    const pieces = this.fileData.filename_download.split('.');
    if (pieces && pieces.length > 0) this.fileData.type = pieces[pieces.length - 1];
    // Set default placeholder in case file has not a preview
    this.defaultPlaceholder = this.isAvailableExtension(this.fileData.type)
      ? `/assets/images/attachments/ico_${this.fileData.type}.svg`
      : '/assets/images/attachments/ico_mp4.svg';
    this.fileURL = `${this._imageURL}${this.fileData.id}`;
  }

  public isAvailableExtension(type: string): boolean {
    const t = type.toLowerCase();
    const extensionFound = Object.values(ExtensionTypes).find((ext: string) => ext === t);
    return extensionFound !== undefined && extensionFound.length > 0;
  }

  public isPreviewAvailable(type: string): boolean {
    const t = type.toLowerCase();
    return t === ExtensionTypes.JPG || t === ExtensionTypes.PNG || t === ExtensionTypes.SVG;
  }

  public isPDFExtension(type: string): boolean {
    const t = type.toLowerCase();
    return t === ExtensionTypes.PDF;
  }

  public downloadImage(fileData: IAttachment, fileURL: string) {
    this._directus.getFileById(fileData.id).subscribe((response: IAttachment) => {
      const fileData = response;
      this._downloadFileService.downLoadImage(fileURL, fileData);
    });
  }

  public openPDF(fileURL: string) {
    window.open(fileURL, '_blank');
  }
}
