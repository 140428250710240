import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, tap } from 'rxjs';

import { ToastComponent } from '../../shared/components/toast/toast.component';
// Interfaces
import { ILiteralsInterceptor, InterceptorMessages } from '../../shared/interfaces/errorsInterface';
import { ToastType } from '../../shared/interfaces/toast.interface';

@Injectable()
export class SuccessInterceptor implements HttpInterceptor {
  private _translationsLiterals: ILiteralsInterceptor = {};
  private _isShowToast = false;

  constructor(private _snackBar: MatSnackBar, private _translate: TranslateService) {
    this._getSuccessTranslations();
  }

  private _getSuccessTranslations() {
    this._translate.get('SUCCESS').subscribe((_successTranslations) => {
      this._translationsLiterals = {
        userManagement: _successTranslations.USER_MANAGEMENT,
        caseManagement: _successTranslations.CASE_MANAGEMENT,
        orderManagement: _successTranslations.ORDER_MANAGEMENT,
        invoiceManagement: _successTranslations.INVOICE_MANAGEMENT,
        openInvoiceManagement: _successTranslations.OPEN_INVOICE_MANAGEMENT,
        profileManagement: _successTranslations.PROFILE_MANAGEMENT,
      };
    });
  }

  intercept(req: HttpRequest<HttpResponse<any>>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.method === 'GET') {
      return next.handle(req);
    }
    return next.handle(req).pipe(
      tap((res: HttpEvent<any>) => {
        if (res instanceof HttpResponse) {
          this._showSuccessToast(req);
        }
        return res;
      })
    );
  }
  /**
   * @description this function get a message error and set the message depending by the url
   * @param request Is a request was executed with error
   * @returns a error in api for a user
   */
  private _getSuccessText(request: HttpRequest<unknown>): string {
    let successMessage = '';
    if (request.url.includes('user')) {
      successMessage = this._getUserManagementMessageSuccess(request);
    }
    if (request.url.includes('case')) {
      successMessage = this._getCasesManagementMessageSuccess(request);
    }
    if (request.url.includes('order')) {
      successMessage = this._getOrderManagementMessageSuccess(request);
    }
    if (request.url.includes('invoices')) {
      successMessage = this._getInvoiceManagementMessageSuccess(request);
    }
    if (request.url.includes('uploadImage')) {
      successMessage = this._getProfileManagementMessageSuccess(request);
    }
    return successMessage;
  }

  private _getUserManagementMessageSuccess(request: HttpRequest<unknown>): string {
    const userMessages: InterceptorMessages = {
      POST: <string>this._translationsLiterals.userManagement?.USER_CREATION,
      PATCH: this._getPatchTypeMessageSuccess(request.url),
    };
    const key: string = <string>request.method;
    const userManageSuccess = userMessages[key] ? userMessages[key] : '';
    return userManageSuccess;
  }

  private _getProfileManagementMessageSuccess(request: HttpRequest<unknown>): string {
    const userMessages: InterceptorMessages = {
      POST: <string>this._translationsLiterals.profileManagement?.UPLOAD_IMAGE,
    };
    const key: string = <string>request.method;
    const userManageSuccess = userMessages[key] ? userMessages[key] : '';
    return userManageSuccess;
  }

  private _getInvoiceManagementMessageSuccess(request: HttpRequest<unknown>): string {
    const userMessages: InterceptorMessages = {
      PATCH: this._getPatchInvoiceMessageSuccess(request.url),
      POST: <string>this._translationsLiterals.invoiceManagement?.INVOICE_ZIP,
    };
    const key: string = <string>request.method;
    const userManageSuccess = userMessages[key] ? userMessages[key] : '';
    return userManageSuccess;
  }

  private _getCasesManagementMessageSuccess(request: HttpRequest<unknown>): string {
    const userMessages: InterceptorMessages = {
      POST: <string>this._translationsLiterals.caseManagement?.CASE_CREATION,
      PATCH: <string>this._translationsLiterals.caseManagement?.CASE_DEACTIVATION,
    };
    const key: string = <string>request.method;
    const userManageSuccess = userMessages[key] ? userMessages[key] : '';
    return userManageSuccess;
  }

  private _getOrderManagementMessageSuccess(request: HttpRequest<unknown>): string {
    const userMessages: InterceptorMessages = {
      PATCH: <string>this._translationsLiterals.orderManagement?.ORDER_EDITION,
    };
    const key: string = <string>request.method;
    const userManageSuccess = userMessages[key] ? userMessages[key] : '';
    return userManageSuccess;
  }

  private _getPatchTypeMessageSuccess(url: string): string {
    if (url.includes('enable')) {
      return <string>this._translationsLiterals.userManagement?.USER_ACTIVATION;
    }
    if (url.includes('disable')) {
      return <string>this._translationsLiterals.userManagement?.USER_DEACTIVATION;
    }
    return <string>this._translationsLiterals.userManagement?.USER_EDITION;
  }

  private _getPatchInvoiceMessageSuccess(url: string): string {
    if (url.includes('nonTreaty')) {
      return <string>this._translationsLiterals.invoiceManagement?.INVOICE_NONTREATY;
    }
    return <string>this._translationsLiterals.invoiceManagement?.INVOICE_TREATY;
  }
  /**
   * @description this function is used for publish a toast error
   * @param request Is a request was executed with error
   */
  private _showSuccessToast(request: HttpRequest<unknown>) {
    const successMessage = this._getSuccessText(request);
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';
    const toastType: ToastType = 'success';
    if (successMessage !== '' && !this._isShowToast) {
      this._snackBar
        .openFromComponent(ToastComponent, {
          duration: 3000,
          horizontalPosition: horizontalPosition,
          verticalPosition: verticalPosition,
          panelClass: ['success-snackbar'],
          data: {
            type: toastType,
            text: successMessage,
          },
        })
        .afterDismissed()
        .subscribe((res) => {
          this._isShowToast = false;
        });
      this._isShowToast = true;
    }
  }
}
