const AlertTypes = {
  ORDERS: 'order',
  INVOICES: 'invoice',
  CASES: 'case',
  PRESENT: 'present',
};

export const AlertTypeLinks = {
  [AlertTypes.ORDERS]: {
    text: 'ALERTS.LINKS.ORDERS',
    external: true,
  },
  [AlertTypes.INVOICES]: {
    text: 'ALERTS.LINKS.INVOICES',
    external: false,
  },
  [AlertTypes.CASES]: {
    text: 'ALERTS.LINKS.CASES',
    external: false,
  },
  [AlertTypes.PRESENT]: {
    text: 'ALERTS.LINKS.PRESENT',
    external: false,
  },
};
