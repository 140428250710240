import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Form, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { IField, IFieldRules, IForm, IFormGroup } from '../../interfaces/form.interface';
import { SidenavService } from '../../services/sidenav.service';
import { availableRules } from '../../utils/form-models/config';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnChanges, OnDestroy {
  @Input() modelForm!: IForm;
  @Output() emitFormValues = new EventEmitter<any>();

  private readonly _emailPattern: string = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.(com|es|net|org|io|gov|edu|mil|cc)$';
  private _subscription: Subscription = new Subscription();

  public dynamicFormGroup: FormGroup = new FormGroup({}); // for string form fields
  public fields: IField[] = [];
  public displayedFields: IField[] = [];

  constructor(private _sidenav: SidenavService) {
    this._subscribeDynamicFormValues();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['modelForm']) this._initializeForm();
  }

  private _subscribeDynamicFormValues(): void {
    this._subscription.add(
      this._sidenav.$newDynamicFormValuesObserver.subscribe((res) => {
        if (this.modelForm.reset) this.dynamicFormGroup.reset();
      })
    );
  }

  public hasHiddenFields(): boolean {
    return this.fields.length !== this.displayedFields.length;
  }

  private _initializeForm(): void {
    this.fields = [];
    const formGroupFields = this._getFormControlsFields();
    this.dynamicFormGroup = new FormGroup(formGroupFields);
    this.displayedFields = this.fields.filter((field: IField) => !field.hide);
  }

  private _getFormControlsFields() {
    const formGroupFields: IFormGroup = {};
    if (this.modelForm)
      this.modelForm.fields.forEach((field: IField) => {
        const { key, rules, value } = field;
        const validators = this._getValidators(rules);
        formGroupFields[key] = new FormControl(value, validators);
        this.fields.push(field);
      });

    return formGroupFields;
  }

  private _getValidators(rules?: IFieldRules): Validators {
    if (!rules) return [];

    const validators = Object.keys(rules).map((rule: string) => {
      if (rule === availableRules.email) {
        return Validators.pattern(this._emailPattern);
      }
      if (rule === availableRules.pattern) {
        return this._patternValidator(rules.pattern!);
      }
      if (rule === availableRules.equalTo) {
        return this._match(rules.equalTo!);
      }
      if (rule === availableRules.maxLength) {
        return this._applyMaxLength(rules.maxlength!);
      }
      return Validators.required;
    });
    return validators;
  }

  public closeDrawer(): void {
    this._sidenav.$openSidenavObserver.next('');
    this._sidenav.$resetFiltersSidenavObserver.next('');
  }

  public formValidator(): boolean {
    return this.dynamicFormGroup.valid;
  }

  private _match(controlName: string): (control: FormControl) => ValidationErrors | null {
    return (control: FormControl) => {
      const matchingControl = this.dynamicFormGroup.controls[controlName];
      if (matchingControl && control.value !== matchingControl.value) {
        return { match: true };
      }
      return null;
    };
  }

  private _applyMaxLength(length: number): (control: FormControl) => ValidationErrors | null {
    return (control: FormControl) => {
      if (control.value.length > length) {
        return { maxlength: length };
      }
      return null;
    };
  }

  private _patternValidator(regex: any): (control: FormControl) => ValidationErrors | null {
    return (control: FormControl) => {
      if (!control.value || !regex) return null;
      const value = control.value;
      const stringValue = value.toString();
      const pattern = regex;
      return pattern.test(stringValue) ? null : { patternError: true };
    };
  }

  public onSubmit(): void {
    if (this.dynamicFormGroup.valid) {
      this.emitFormValues.emit(this.dynamicFormGroup.value);
    }
  }
}
