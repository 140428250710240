<div *ngIf="props" class="iframe">
  <iframe
    [title]="props.title"
    [src]="safeUrl"
    [width]="props.width || 100"
    [height]="props.height || 500"
    class="iframe__video"
    allowFullScreen
    frameBorder="no"
    referrerPolicy="no-referrer-when-downgrade"
    allow="autoplay"
  ></iframe>
</div>
