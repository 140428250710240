import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderFooterVisibilityService {
  public headerAndFooterVisibilitySubject: Subject<boolean> = new Subject<boolean>();
  public $profileImageUpdated = new Subject<string>();
  public changeHeaderAndFooterVisibility(isVisible: boolean): void {
    this.headerAndFooterVisibilitySubject.next(isVisible);
  }
}
