<!-- Dynamic field with expansion pannel (accordion) -->
<mat-expansion-panel *ngIf="accordion" class="form__accordion" togglePosition="before">
  <mat-expansion-panel-header>
    <mat-panel-title
      ><h6>{{ field.label }}</h6></mat-panel-title
    > </mat-expansion-panel-header
  ><ng-container #dynamicInputContainer></ng-container>
</mat-expansion-panel>

<!-- Dynamic field WITHOUT accordion -->
<ng-container *ngIf="!accordion" #dynamicInputContainer></ng-container>
