<form [formGroup]="formName" class="field field__radio">
  <mat-label *ngIf="field.label && !field.hideLabel" class="field__label">{{ field.label }}</mat-label>
  <mat-radio-group class="radio__options" aria-label="Select an option" [formControlName]="field.key">
    <div class="radio__option" *ngFor="let option of field.options">
      <input
        class="option__input"
        type="radio"
        [id]="option.label"
        [name]="field.key"
        [value]="option.value"
        [checked]="formName.controls[field.key].value === option.key"
      />
      <label class="option__label" [for]="option.label" (click)="handleDynamicHint(option)">{{ option.label }}</label>
    </div>
  </mat-radio-group>
  <mat-hint *ngIf="hint" class="field__hint">{{ hint }}</mat-hint>
</form>
