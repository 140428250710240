import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IButtonConfig } from '../../interfaces/button.interface';
import { searchButton } from '../button/ui-button-configs/ui-button-config';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  public searchButtonConfig: IButtonConfig = searchButton;
  @Input() searchValue = '';
  // FIXME: hardcoded text
  @Input() placeholder = 'Buscar...';
  @Output() searchEmit = new EventEmitter<string>();

  public handleSearch() {
    this.searchEmit.emit(this.searchValue);
  }

  public updateSearchValue(newValue: string) {
    this.searchValue = newValue;
  }
}
