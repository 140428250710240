import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, throwError } from 'rxjs';

import { ToastComponent } from '../../shared/components/toast/toast.component';
import { ILiteralsInterceptor, InterceptorMessages } from '../../shared/interfaces/errorsInterface';
import { ToastType } from '../../shared/interfaces/toast.interface';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public translationsLiterals: ILiteralsInterceptor = {};
  private _isSnackBarOpen = false;

  constructor(private _snackBar: MatSnackBar, private _translate: TranslateService) {
    this.getErrorTranslations();
  }
  getErrorTranslations() {
    this._translate.get('ERRORS').subscribe((_errorTranslations) => {
      this.translationsLiterals = {
        userManagement: _errorTranslations.USER_MANAGEMENT,
        caseManagement: _errorTranslations.CASE_MANAGEMENT,
        orderManagement: _errorTranslations.ORDER_MANAGEMENT,
        invoiceManagement: _errorTranslations.INVOICE_MANAGEMENT,
        openInvoiceManagement: _errorTranslations.OPEN_INVOICE_MANAGEMENT,
        profileManagement: _errorTranslations.PROFILE_MANAGEMENT,
      };
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this._showErrorToast(request, error);
        return throwError(() => error);
      })
    );
  }
  /**
   * @description this function get a message error and set the message depending by the url
   * @param request Is a request was executed with error
   * @returns a error in api for a user
   */
  private _getErrorText(request: HttpRequest<unknown>, error: HttpErrorResponse): string | undefined {
    let errorMessage = '';
    if (request.url.includes('user')) {
      errorMessage = this._getUserManagementMessageError(request, error);
    }
    if (request.url.includes('case')) {
      errorMessage = this._getCasesManagementMessageError(request);
    }
    if (request.url.includes('order')) {
      errorMessage = this._getOrderManagementMessageError(request);
    }
    if (request.url.includes('invoices')) {
      if (request.url.includes('listopenitems') || request.url.includes('downloadOpenItemExcel'))
        errorMessage = this._getOpenInvoiceManagementMessageError(request);
      else errorMessage = this._getInvoiceManagementMessageError(request);
    }
    if (request.url.includes('uploadImage')) {
      errorMessage = this._getProfileManagementMessageError(request);
    }
    return errorMessage;
  }

  private _getUserManagementMessageError(request: HttpRequest<unknown>, error: HttpErrorResponse): string {
    const userMessages: InterceptorMessages = {
      POST: <string>this._getUserPostError(error),
      GET: <string>this.translationsLiterals.userManagement?.USER_LIST,
      PATCH: this._getPatchTypeMessageError(request.url),
    };
    const key: string = <string>request.method;
    const userManageError = userMessages[key] ? userMessages[key] : '';
    return userManageError;
  }

  private _getUserPostError(error: HttpErrorResponse): string {
    if (error.error.data.error === 'Error in user creation, email already exists') {
      return this.translationsLiterals?.userManagement?.USER_CREATION_DUPLICATED_MAIL || '';
    }
    return this.translationsLiterals.userManagement?.USER_CREATION || '';
  }

  private _getInvoiceManagementMessageError(request: HttpRequest<unknown>): string {
    const userMessages: InterceptorMessages = {
      GET: <string>this.translationsLiterals.invoiceManagement?.INVOICE_LIST,
      PATCH: <string>this.translationsLiterals.invoiceManagement?.INVOICE_EDITION,
      POST: <string>this.translationsLiterals.invoiceManagement?.INVOICE_ZIP,
    };
    const key: string = <string>request.method;
    const userManageError = userMessages[key] ? userMessages[key] : '';
    return userManageError;
  }

  private _getOpenInvoiceManagementMessageError(request: HttpRequest<unknown>): string {
    const userMessages: InterceptorMessages = {
      GET: <string>this.translationsLiterals.openInvoiceManagement?.OPEN_INVOICE_LIST,
      PATCH: <string>this.translationsLiterals.openInvoiceManagement?.OPEN_INVOICE_EDITION,
      POST: <string>this.translationsLiterals.openInvoiceManagement?.OPEN_INVOICE_ZIP,
    };
    const key: string = <string>request.method;
    const userManageError = userMessages[key] ? userMessages[key] : '';
    return userManageError;
  }

  private _getCasesManagementMessageError(request: HttpRequest<unknown>): string {
    const userMessages: InterceptorMessages = {
      POST: <string>this.translationsLiterals.caseManagement?.CASE_CREATION,
      GET: <string>this.translationsLiterals.caseManagement?.CASE_LIST,
      PATCH: <string>this.translationsLiterals.caseManagement?.CASE_DEACTIVATION,
    };
    const key: string = <string>request.method;
    const userManageError = userMessages[key] ? userMessages[key] : '';
    return userManageError;
  }

  private _getProfileManagementMessageError(request: HttpRequest<unknown>): string {
    const userMessages: InterceptorMessages = {
      POST: <string>this.translationsLiterals.profileManagement?.UPLOAD_IMAGE,
    };
    const key: string = <string>request.method;
    const userManageError = userMessages[key] ? userMessages[key] : '';
    return userManageError;
  }

  private _getOrderManagementMessageError(request: HttpRequest<unknown>): string {
    const userMessages: InterceptorMessages = {
      GET: <string>this.translationsLiterals.orderManagement?.ORDER_LIST,
      PATCH: <string>this.translationsLiterals.orderManagement?.ORDER_EDITION,
    };
    const key: string = <string>request.method;
    const userManageError = userMessages[key] ? userMessages[key] : '';
    return userManageError;
  }

  private _getPatchTypeMessageError(url: string): string {
    if (url.includes('enable')) {
      return <string>this.translationsLiterals.userManagement?.USER_ACTIVATION;
    }
    if (url.includes('disable')) {
      return <string>this.translationsLiterals.userManagement?.USER_DEACTIVATION;
    }
    return <string>this.translationsLiterals.userManagement?.USER_EDITION;
  }

  /**
   * @description this function is used for publish a toast error
   * @param request Is a request was executed with error
   */
  private _showErrorToast(request: HttpRequest<unknown>, error: HttpErrorResponse) {
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';
    const toastType: ToastType = 'error';
    const errorText = this._getErrorText(request, error);
    if (errorText && !this._isSnackBarOpen) {
      this._snackBar
        .openFromComponent(ToastComponent, {
          duration: 2000,
          horizontalPosition: horizontalPosition,
          verticalPosition: verticalPosition,
          panelClass: ['error-snackbar'],
          data: {
            type: toastType,
            text: errorText,
          },
        })
        .afterDismissed()
        .subscribe(() => {
          this._isSnackBarOpen = false;
        });
      this._isSnackBarOpen = true;
    }
  }
}
