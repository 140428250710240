<mat-card class="card__container">
  <div
    class="card__header"
    [style]="card.header ? 'background-image: url(' + card.header.id + ');' : ''"
    [style.background-position]="card.referencia_img"
  >
    <div class="header__tags">
      <mat-icon *ngIf="card.starred" color="accent">stars</mat-icon>
      <app-tag [tagText]="card.type" />
      <div *ngFor="let hashtag of card.hashtags">
        <app-tag [tagText]="hashtag" />
      </div>
    </div>
  </div>
  <mat-card-content class="card__content">
    <p>{{ formattedDate }}</p>
    <h6>{{ card.title }}</h6>
    <a [href]="'/timeline/article/' + card.id">{{ 'DASHBOARD.TIMELINE.READ' | translate }}</a>
  </mat-card-content>
</mat-card>
