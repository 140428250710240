import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';

// Interfaces
import { IDialogText } from '../../interfaces/dialog.interface';
import { IProfileData } from '../../interfaces/profile.interface';
// Services
import { AuthService } from '../../../core/services/auth.service';
import { AlertsService } from '../../ports/alerts-http.service';
import { ProfileHttpService } from '../../ports/profile-http.service';
import { HeaderFooterVisibilityService } from '../../services/header-footer-visibility.service';
import { SidenavService } from '../../services/sidenav.service';
// Components
import { AlertsComponent } from '../alerts/alerts.component';
import { DialogComponent } from '../dialog/dialog.component';

import { environment } from '../../../../environments/environment';
import { ProfileTabIndex } from '../../utils/enums/profileTabIndex.model';
import { Roles } from '../../utils/enums/roles.model';
import { getNameInitials } from '../../utils/utils';

export interface NavItem {
  name: string;
  url: string;
  icon: string;
  hasPermission?: string;
  externalUrl?: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  private _userRoles: string[] = [];
  public isMenuOpen = true;
  public profileData: IProfileData | undefined;
  public unreadAlerts = 0;
  public activeNavItem = 'dashboard';
  public existProfileImage = false;
  public profileTabIndex = ProfileTabIndex;
  public profileImage = '';
  public profileName = '';
  public navItems: NavItem[] = [
    {
      name: 'Pedidos',
      url: 'order_service',
      icon: 'launch',
      externalUrl: environment.tools.ordersPortal,
    },
    { name: 'Facturación', url: 'invoices', icon: '' },
    { name: 'Universo HEINEKEN', url: 'heineken_universe', icon: '' },
    { name: 'Ponte al día', url: 'timeline', icon: '' },
  ];

  public profileItems: NavItem[] = [
    { name: 'Consultas', url: 'cases', icon: '' },
    { name: 'Avisos', url: 'alerts', icon: 'new_releases' },
    { name: '', url: 'profile', icon: '' },
  ];

  public userManagementItems: NavItem[] = [
    { name: 'Gestión de usuarios', url: 'profile', icon: '', hasPermission: 'userManagement' },
  ];

  private _dialogTranslations: IDialogText = {};
  private _imageUserUrl = `${environment.urlDomains.baseUrlImage}`;
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _matIconRegistry: MatIconRegistry,
    private _profileHttpService: ProfileHttpService,
    private _domSanitizer: DomSanitizer,
    private _sidenavService: SidenavService,
    private _translate: TranslateService,
    private _alertsService: AlertsService,
    private _headerService: HeaderFooterVisibilityService,
    public dialog: MatDialog
  ) {
    this._userRoles = this._authService.getUserRol();
    if (this._userRoles && this._userRoles.includes(Roles.ADMINISTRADOR)) {
      this._headerService.changeHeaderAndFooterVisibility(false);
      return;
    }
    this._getProfileData();
    this._getQueryParams();
    this._loadIcons();
    this._getProfileName();
    this._getTranslations();
    this._getProfileImage();
  }

  ngOnInit() {
    if (this._userRoles && this._userRoles.includes(Roles.ADMINISTRADOR)) {
      this._headerService.changeHeaderAndFooterVisibility(false);
      return;
    }
    this.changeProfileImage();
    this._subscribeToProfileImageChange();
    this._subscribeToUnreadAlerts();
  }

  public toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  public closeMenu() {
    this.isMenuOpen = true;
  }

  public changeProfileImage(): void {
    if (this.profileData?.profile_image_path) {
      this.existProfileImage = true;
    } else {
      this.existProfileImage = false;
    }
  }

  private _subscribeToUnreadAlerts() {
    this._alertsService.$unreadAlerts.subscribe(() => {
      this._getAlerts();
    });
  }

  private _subscribeToProfileImageChange() {
    this._headerService.$profileImageUpdated.subscribe((data) => {
      this._getProfileImage();
      this.changeProfileImage();
    });
  }

  private _getProfileImage(): void {
    const inicioRuta = this._imageUserUrl;

    this._profileHttpService.getProfileData().subscribe((profile) => {
      if (profile?.profile_image_path) {
        this.profileImage = `${inicioRuta}${profile.profile_image_path}`;
        this.existProfileImage = true;
      }
    });
  }

  public getProfileNameResponsive(): string {
    if (this.profileData && this.profileData.surname) {
      const initial = this.profileData.surname.charAt(0);
      return `${this.profileData.name} ${initial}.`;
    }
    return '';
  }

  private _getProfileData(): void {
    this._profileHttpService.getProfileData().subscribe((profile: IProfileData) => {
      this.profileData = profile;
    });
  }

  public nameInitials(): string {
    return getNameInitials(this.profileData);
  }

  private _getTranslations(): void {
    this._translate.get('DIALOG.CLOSE_SESSION').subscribe((_dialogTranslations) => {
      this._dialogTranslations = {
        title: _dialogTranslations.TITLE,
        text: _dialogTranslations.TEXT,
        actions: {
          confirm: _dialogTranslations.ACTIONS.CONFIRM,
          cancel: _dialogTranslations.ACTIONS.CANCEL,
        },
      };
    });
  }

  private _getAlerts(): void {
    this._alertsService.getNumberUnreadAlerts().subscribe((amount: number) => {
      this.unreadAlerts = amount;
    });
  }

  public showDialog(): void {
    const dialog = this.dialog.open(DialogComponent, {
      data: {
        title: this._dialogTranslations.title,
        text: this._dialogTranslations.text,
        actions: {
          confirm: {
            text: this._dialogTranslations.actions?.confirm,
            value: 'confirm',
          },
          close: {
            text: this._dialogTranslations.actions?.cancel,
            value: 'cancel',
          },
        },
      },
    });
    this._closeModalHandler(dialog);
  }

  private _closeModalHandler(dialog: MatDialogRef<DialogComponent>): void {
    dialog
      .afterClosed()
      .pipe(finalize(() => console.log('completed')))
      .subscribe((result: string) => {
        if (result === 'confirm') {
          this.signOut();
          return;
        }
      });
  }

  private _getProfileName(): void {
    this._profileHttpService.getProfileData().subscribe((profile) => {
      const completeName = this._constructCompleteName(profile.name, profile.surname);
      this.profileItems[this.profileItems.length - 1].name = completeName;
    });
  }

  private _constructCompleteName(name: string, surname?: string): string {
    if (surname) {
      const surnameInitial = surname ? surname.charAt(0).toUpperCase() : '';
      return `${name} ${surnameInitial}.`;
    }
    return name;
  }

  private _loadIcons(): void {
    this._matIconRegistry.addSvgIcon(
      'ico-bell',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/ico_bell.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'ico-headphones',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/ico_headphones.svg')
    );
  }

  private _getQueryParams() {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.urlAfterRedirects;
        this._setActiveNavItemByUrl(currentUrl);
        this._setActiveNavItemByUrlProfile(currentUrl);
      }
    });
  }

  public setActiveNavItem(navItem: NavItem) {
    if (navItem.externalUrl) {
      window.open(navItem.externalUrl, '_blank');
      return;
    }
    if (navItem.url === 'alerts') {
      this._sidenavService.$openSidenavObserver.next(AlertsComponent);
      return;
    }
    this.activeNavItem = navItem.url;
    this._router.navigate([navItem.url]);
  }

  public setIndexProfile(navItem: NavItem, TabSection: ProfileTabIndex) {
    this._router.navigate([navItem.url], { queryParams: { id: TabSection } });
  }

  public setIndexProfileResponsive(TabSection: ProfileTabIndex) {
    const navItem = 'profile';
    this._router.navigate([navItem], { queryParams: { id: TabSection } });
  }

  public signOut(): void {
    this._authService.signOut();
  }

  public navigateAtHome() {
    this._router.navigate(['/dashboard']);
    this.activeNavItem = 'dashboard';
  }

  private _setActiveNavItemByUrl(url: string) {
    const navItem = this.navItems.find((item) => url.includes(item.url));
    if (navItem) {
      this.activeNavItem = navItem.url;
    }
    this.isMenuOpen = true;
  }

  private _setActiveNavItemByUrlProfile(url: string) {
    const profileItem = this.profileItems.find((item) => url.includes(item.url));
    if (profileItem) {
      this.activeNavItem = profileItem.url;
    }
  }
}
