import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IEmptyData } from '../../interfaces/emptyData.interface';
import { dashboardOrdersButtonConfig } from '../button/ui-button-configs/ui-button-config';
import { initEmptyDataWithTemplate } from '../../utils/utils';

@Component({
  selector: 'app-empty-data',
  templateUrl: './empty-data.component.html',
  styleUrls: ['./empty-data.component.scss'],
})
export class EmptyDataComponent {
  @Input() emptyData: IEmptyData = initEmptyDataWithTemplate('small')('pedidos.svg', dashboardOrdersButtonConfig);
  @Input() isInDashboard = false;
  @Output() buttonClicked = new EventEmitter<any>();

  public handleButtonClicked(event: any) {
    this.buttonClicked.emit(event);
  }
}
