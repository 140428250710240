import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { IUser } from '../interfaces/form.interface';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  public $openSidenavObserver = new BehaviorSubject<any>('');
  public $resetFiltersSidenavObserver = new Subject<string>();
  public $newDynamicFormValuesObserver = new Subject<IUser>();
  public isDrawerOpen = new BehaviorSubject<boolean>(false);

  public setIsDrawerOpen(opened: boolean) {
    this.isDrawerOpen.next(opened);
  }
}
