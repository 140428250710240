export const CategoryCatalog = [
  {
    title: 'AAEE de clientes',
    image: 'assets/images/case-categories/ico-category-tools.svg',
    queries: ['Seguimiento AAEE clientes', 'Información Solicitud de Amortización de AAEE'].sort(),
  },
  {
    title: 'Calidad de Producto',
    image: 'assets/images/case-categories/ico-category-quality.svg',
    queries: ['Consulta alérgenos', 'Incidencia en instalación de punto de venta', 'Otras dudas de calidad'].sort(),
  },
  {
    title: 'CLAVE',
    image: 'assets/images/case-categories/ico-category-clave2.png',
    queries: [
      'Modificar mi jerarquia de proveedores',
      'He olvidado mi contraseña CLAVE',
      'Error en diferencia de datos con mi ERP',
      'Error en los cuadros de mando',
      'Error funcionamiento de CLAVE',
      'Errores en la transmisión de ERP a CLAVE',
      'Otros temas de Clave',
      'Dudas Funcionamiento de la herramienta',
      'Cambiar el usuario y/o email del gerente',
    ].sort(),
  },
  {
    title: 'Facturación',
    image: 'assets/images/case-categories/ico-category-billing.svg',
    queries: [
      'Aplazamiento de deuda',
      'Facturas disponibles',
      'Solicitud compensaciones',
      'Reclamación de abonos',
      'Detalles domiciliacion',
      'Orion',
      'Reclamacion factura no recibida',
      'Pedidos no facturados',
      'Reclamación diferencia de devoluciones',
      'Cambio forma de pago',
      'Consulta Esfuerzo comercial',
      'Cambio cuenta bancaria/correo electrónico',
      'Informes Fiscales y Modelo 347',
      'Reclamación factura albarán envases',
      'Otro tipo de información',
    ].sort(),
  },
  {
    title: 'Gestion con tu equipo Distribución',
    image: 'assets/images/case-categories/ico-category-commercial.svg',
    queries: ['Acuerdos con terceros', 'Decoración de flotas', 'Decoración de naves', 'Otras dudas'],
  },
  {
    title: 'Herramientas',
    image: 'assets/images/case-categories/ico-category-tools.svg',
    queries: [
      'App de Mercado contraseña',
      'App de Mercado dudas',
      'App de Mercado usuarios',
      'App Tu Bar contraseña',
      'App Tu Bar dudas',
      'App Tu Bar pedidos',
      'App Tu Bar cambio datos',
      'Plan vasos',
      'HEINET',
      'Otras consultas de Herramientas',
    ].sort(),
  },
  {
    title: 'Hesanet',
    image: 'assets/images/case-categories/ico-category-Hesanet.svg',
    queries: [
      'Consulta funcionamiento Hesanet',
      'Consultas/error transmisión hesanet',
      'Duda o Incidencia sobre el ECC',
      'Error transmisión ventas',
      'Log de errores',
      'Contraseña Hesanet',
    ].sort(),
  },

  {
    title: 'Nautilus',
    image: 'assets/images/case-categories/ico-category-Nautilus.svg',
    queries: ['Error Funcionamiento', 'Error Acceso', 'Consulta de Liquidaciones', 'Informes Nautilus'].sort(),
  },
  {
    title: 'Pedidos',
    image: 'assets/images/case-categories/ico-category-orders.svg',
    queries: [
      'Modificación pedido',
      'Pedido urgente',
      'Retirada de envase',
      //'Solicitud tapas de barril, pallet',
      'Retrasar un pedido',
      'Error acceso portal pedidos',
      //'Error en la cesta',
      'Error en el catálogo',
      'Error al solicitar producto',
      'Otros temas de pedidos',
      'Disponibilidad de producto',
      'Devolución de producto',
      'Reclamaciones de Abono',
      'Consulta empresa de transporte',
      'Consulta hora de carga, horarios',
      'Localizacion de pedido',
      'Estado de mi pedido',
    ].sort(),
  },
  {
    title: 'PLV',
    image: 'assets/images/case-categories/ico-category-plv.svg',
    queries: [
      'Composición del pedido',
      'Compra/solicitud del pedido',
      'Destino incorrecto',
      'Fecha entrega del pedido',
      'Incidencia en PDV con botelleros',
      'Material mal etiquetado',
      'PLV recibido en mal estado',
    ].sort(),
  },
  {
    title: 'Suministros a terceros',
    image: 'assets/images/case-categories/ico-category-thirdParties.svg',
    queries: [
      'Error en la transmisión de albaran',
      'Modificación de albarán',
      'Consulta de código cliente HHCC',
      'Consulta de tarifas de almacenajes y suministros',
    ].sort(),
  },
  {
    title: 'Otros',
    image: 'assets/images/case-categories/ico-category-tools.svg',
    queries: ['Modificar mis datos', 'Sugerencias HK'].sort(),
  },
];

export const typeOfCaseToNavigate: ItypeOfCaseToNavigate = {
  companyData: {
    category: CategoryCatalog.find((c: any) => c.title.toLowerCase() === 'otros'),
    query: 'Modificar mis datos',
  },
  footer: {
    category: CategoryCatalog.find((c: any) => c.title.toLowerCase() === 'otros'),
    query: 'Sugerencias HK',
  },
};
export const SearchResults = {
  title: 'Resultados',
  image: 'assets/images/empty-data-img/ico_empty_busqueda.svg',
  queries: [],
};
export interface ISearchResults {
  title: string;
  image: string;
  queries: string[];
}
interface ItypeOfCaseToNavigate {
  [key: string]: {
    category:
      | {
          title: string;
          image: string;
          queries: string[];
        }
      | undefined;
    query: string;
  };
}
