<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="a">
    <th mat-header-cell *matHeaderCellDef>
      <ngx-skeleton-loader [theme]="skeletonTableTheme" count="1"></ngx-skeleton-loader>
    </th>
    <td mat-cell *matCellDef>
      <ngx-skeleton-loader [theme]="skeletonTableTheme" count="1"></ngx-skeleton-loader>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="b">
    <th mat-header-cell *matHeaderCellDef>
      <ngx-skeleton-loader [theme]="skeletonTableTheme" count="1"></ngx-skeleton-loader>
    </th>
    <td mat-cell *matCellDef>
      <ngx-skeleton-loader [theme]="skeletonTableTheme" count="1"></ngx-skeleton-loader>
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="c">
    <th mat-header-cell *matHeaderCellDef>
      <ngx-skeleton-loader [theme]="skeletonTableTheme" count="1"></ngx-skeleton-loader>
    </th>
    <td mat-cell *matCellDef>
      <ngx-skeleton-loader [theme]="skeletonTableTheme" count="1"></ngx-skeleton-loader>
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="d">
    <th mat-header-cell *matHeaderCellDef>
      <ngx-skeleton-loader [theme]="skeletonTableTheme" count="1"></ngx-skeleton-loader>
    </th>
    <td mat-cell *matCellDef>
      <ngx-skeleton-loader [theme]="skeletonTableTheme" count="1"></ngx-skeleton-loader>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
