import { environment } from '../../../../environments/environment';
import { ITool } from '../../interfaces/tool.interface';

export const MainTools: ITool[] = [
  {
    title: 'Portal Pedidos',
    logo: '/assets/images/tools/tool-orders.svg',
    summary: 'Accede para realizar, modificar o visualizar tus pedidos.',
    link: environment.tools.ordersPortal,
  },
  {
    title: 'Nautilus',
    logo: '/assets/images/tools/tool-nautilus.svg',
    summary: 'Accede al portal de Liquidaciones y Descuentos con HEINEKEN.',
    link: environment.tools.nautilusPortal,
  },
  {
    title: '/assets/images/tools/tool-hesanet.svg',
    logo: '',
    summary: 'Accede a Transmisiones o Reporting Comercial entre otras funcionalidades.',
    link: environment.tools.hesanetPortal,
  },
  {
    title: 'Órdenes comerciales',
    logo: '/assets/images/tools/tool-commercial.svg',
    summary: 'Gestiona montajes, desmontajes o cambios comerciales.',
    link: environment.tools.ordenesComerciales,
  },
  {
    title: 'App Tu bar',
    logo: '/assets/images/tools/tool-tu_bar.svg',
    summary: 'Implementa elementos de visibilidad como cartas y pizarras.',
    link: environment.tools.appTuBar,
  },
];

export const OtherTools: ITool[] = [
  {
    title: 'Clave',
    logo: '/assets/images/logo_clave.svg',
    summary: '',
    link: environment.tools.clavePortal,
  },
  {
    title: '',
    logo: '/assets/images/fuerzabar-eazle.svg',
    summary: '',
    link: environment.tools.fuerzaBarPortal,
  },
];
