type ITextColors = {
  [key: string]: string;
};

export const TEXT_COLORS: ITextColors = {
  No: 'red',
  Nuevo: 'blue',
  ['En gestión']: 'yellow',
  Sí: 'green',
  Cerrado: 'green',
  hashtag: 'white',
  default: 'default',
};
