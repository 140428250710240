<div class="carousel">
  <div class="carousel__header tile__header">
    <ng-content select="[header]"></ng-content>
    <div class="header__arrows">
      <ng-content select="[action]"></ng-content>
      <button [disabled]="counter <= 0" mat-button (click)="moveLeft()">
        <mat-icon fontIcon="keyboard_arrow_left"></mat-icon>
      </button>
      <button [disabled]="counter + cardsVisible >= cardCount" mat-button (click)="moveRight()">
        <mat-icon fontIcon="keyboard_arrow_right"></mat-icon>
      </button>
    </div>
  </div>
  <div class="carousel__content">
    <ng-content select="[body]" #cards></ng-content>
  </div>
</div>
