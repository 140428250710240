import { Component, Input } from '@angular/core';

import { IButtonConfig } from '../../interfaces/button.interface';
import { defaultButtonConfig } from './ui-button-configs/ui-button-config';
import { ButtonType } from '../../interfaces/form.interface';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() buttonConfig: IButtonConfig = defaultButtonConfig;
  @Input() type: ButtonType = 'button'; // For button property: submit, reset or button
  @Input() disabled = false;

  constructor(private _matIconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer) {
    this._loadIcons();
  }

  private _loadIcons(): void {
    this._matIconRegistry.addSvgIcon(
      'ico_filter',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/ico_filter.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'ico_nofilter',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/ico_nofilter.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'ico_download',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/ico_download.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'ico_excel',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/ico_excel.svg')
    );
  }

  public getClass(): string {
    const { iconPosition, size, theme } = this.buttonConfig;
    return `ui-button ui-button--${iconPosition} ${size} button--${theme}`;
  }
}
