import { IProfileData } from '../interfaces/profile.interface';
import { IEmptyData, IEmptyDataTemplate } from '../interfaces/emptyData.interface';
import { IButtonConfig } from '../interfaces/button.interface';

export function firstLetterUpper(text: string) {
  const upperCasedText = text.toLowerCase().replace(/(^\s*\w|[\\.\\!\\?]\s*\w)/g, function (c) {
    return c.toUpperCase();
  });
  return upperCasedText;
}

/**
 * Transform string date into locale format or dd/mm/yyyy format by default
 * @returns {string} transformed date
 */
export function getDateParsed(dateToTransform?: string, locale = 'es-ES', hour = false): string {
  if (!dateToTransform) return '';
  if (!hour)
    return new Date(dateToTransform).toLocaleString(locale, {
      timeZone: 'Europe/Madrid',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

  return new Date(dateToTransform).toLocaleString(locale, {
    timeZone: 'Europe/Madrid',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function getNameInitials(profileData?: IProfileData): string {
  if (!profileData) return '';

  let nameInitial = '';
  let surnameInitial = '';
  if (profileData.name) nameInitial = profileData.name.charAt(0).toUpperCase();
  if (profileData.surname) surnameInitial = profileData.surname.charAt(0).toUpperCase();
  return `${nameInitial}${surnameInitial}`;
}

/**
 * Calculates the differente between date and now time
 * @returns {number} elapsed time in hours
 */
export function calculateElapsedTimeFromNow(date: string): number {
  const d = new Date(date);
  const now = new Date();
  let diff = now.getTime() - d.getTime();
  // Convert milliseconds to hours
  diff /= 3600000;
  // Add timezone offset
  const offsetInHours = now.getTimezoneOffset() / 60;
  diff += offsetInHours;

  return Math.abs(diff);
}

export function removeDuplicatesOfArray(array: Array<any>): Array<any> {
  return array.filter((element: any, index: number) => array.indexOf(element) === index);
}

/**
 * Creates a new object of interface IEmptyData with given template
 * @returns function to specify imageName and button config if so.
 */
export function initEmptyDataWithTemplate(template: IEmptyDataTemplate) {
  return (imageName: string, buttonConfig?: IButtonConfig) => {
    const emptyData: IEmptyData = {
      template,
      img: `assets/images/empty-data-img/ico_empty_${imageName}`,
      title: '',
      body: '',
    };
    if (buttonConfig) {
      emptyData.buttonText = '';
      emptyData.buttonConfig = buttonConfig;
    }
    return emptyData;
  };
}

export function isElementInViewport(element: any) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom - 50 <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
