import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { IField } from '../../../../interfaces/form.interface';

@Component({
  selector: 'app-checkbox-field',
  templateUrl: './checkbox-field.component.html',
  styleUrls: ['./checkbox-field.component.scss'],
})
export class CheckboxFieldComponent {
  @Input() field!: IField;
  @Input() width?: number = 70;
  formName: FormGroup;

  constructor(private _formgroupDirective: FormGroupDirective) {
    this.formName = this._formgroupDirective.control;
  }
}
