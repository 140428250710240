import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { IField } from '../../interfaces/form.interface';

/**
 * Creates a dynamic component depending on the type of field
 * @input field: IField
 * @output correspondant component of field-types directory
 */
@Component({
  selector: 'app-dynamic-field',
  templateUrl: './dynamic-field.component.html',
  styleUrls: ['./dynamic-field.component.scss'],
})
export class DynamicFieldComponent implements AfterViewInit, OnInit {
  @Input() field!: IField;
  @Input() width?: number = 70;
  @Input() accordion = false;
  @ViewChild('dynamicInputContainer', { read: ViewContainerRef }) dynamicInputContainer!: ViewContainerRef;

  constructor(private _changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    //console.log(this.field);
    this.orderOptions();
  }

  ngAfterViewInit() {
    // Reset container
    this.dynamicInputContainer.clear();
    this._registerDynamicField();
    this._changeDetector.detectChanges();
  }

  private orderOptions(): void {
    if (this.field.key == 'labelIncidentType') this.field.options?.sort((a, b) => a.label.localeCompare(b.label));
  }

  // Set dynamic selection of component depending on type
  private _registerDynamicField(): void {
    if (this.accordion) this.field.hideLabel = true;
    const _dynamicComponent = this.dynamicInputContainer.createComponent(this.field.type.template);
    _dynamicComponent.setInput('field', this.field);

    const width = this.width ? this.width : 70;
    _dynamicComponent.setInput('width', width);
  }
}
