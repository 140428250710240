<mat-drawer-container class="container">
  <mat-drawer-content class="container__content">
    <ng-content></ng-content>
  </mat-drawer-content>
  <mat-drawer #drawer class="container__drawer" position="end">
    <mat-icon class="drawer__close" (click)="drawer.close()">close</mat-icon>
    <ng-container *ngComponentOutlet="component"></ng-container>
    <app-dynamic-form *ngIf="modelForm" [modelForm]="modelForm" (emitFormValues)="addUser($event)"></app-dynamic-form>
  </mat-drawer>
</mat-drawer-container>
