import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

import { IField, IFieldOption } from '../../../../interfaces/form.interface';

@Component({
  selector: 'app-radio-field',
  templateUrl: './radio-field.component.html',
  styleUrls: ['./radio-field.component.scss'],
})
export class RadioFieldComponent implements OnInit {
  @Input() field!: IField;
  @Input() width?: number = 70;
  formName: FormGroup;
  hint = '';

  ngOnInit(): void {
    this.ifFieldOptions();
  }

  constructor(private _formgroupDirective: FormGroupDirective) {
    this.formName = this._formgroupDirective.control;
  }

  public ifFieldOptions(): void {
    if (this.field.options && this.field.options.length > 0) {
      const selectedOption = this.field.options.find((option: IFieldOption) => option.value === this.field.value);
      if (selectedOption) {
        this.handleDynamicHint(selectedOption);
      }
    }
  }
  handleDynamicHint(option: IFieldOption): void {
    if (this.field.options && this.field.options.length > 0) {
      const prev = this.formName.value;
      // Save selected option
      prev[this.field.key] = option.key;
      this.formName.setValue(prev);
      this.hint = option.hint ? option.hint : '';
    }
  }
}
