import { IButtonConfig } from '../../../interfaces/button.interface';
import { ButtonIconPosition, ButtonSize, ButtonThemes } from '../../../utils/enums/uiButtonTypes.model';

export const defaultButtonConfig: IButtonConfig = {
  size: ButtonSize.l,
  theme: ButtonThemes.primary,
  icon: '',
  iconPosition: ButtonIconPosition.left,
};

export const secondaryDefaultButtonConfig: IButtonConfig = {
  size: ButtonSize.l,
  theme: ButtonThemes.secondary,
  icon: '',
  iconPosition: ButtonIconPosition.left,
};

export const dashboardOrdersButtonConfig: IButtonConfig = {
  size: ButtonSize.s,
  theme: ButtonThemes.secondary,
  icon: 'launch',
  iconPosition: ButtonIconPosition.right,
};

export const bigEmptyDataButtonConfig: IButtonConfig = {
  size: ButtonSize.l,
  theme: ButtonThemes.primary,
  icon: 'add',
  iconPosition: ButtonIconPosition.left,
};

export const warProfileDataButton: IButtonConfig = {
  size: ButtonSize.s,
  theme: ButtonThemes.warn,
  icon: 'exit_to_app',
  iconPosition: ButtonIconPosition.right,
};

export const invoicesMarkAsButton: IButtonConfig = {
  size: ButtonSize.s,
  theme: ButtonThemes.primary,
  icon: 'keyboard_arrow_down',
  iconPosition: ButtonIconPosition.right,
};

export const invoicesExcelButton: IButtonConfig = {
  size: ButtonSize.s,
  theme: ButtonThemes.secondary,
  icon: 'play_for_work',
  svg: 'ico_excel',
  iconPosition: ButtonIconPosition.left,
};

export const invoicesPDFButton: IButtonConfig = {
  size: ButtonSize.s,
  theme: ButtonThemes.secondary,
  icon: 'picture_as_pdf',
  svg: 'ico_download',
  iconPosition: ButtonIconPosition.left,
};

export const invoiceFilterButton: IButtonConfig = {
  size: ButtonSize.s,
  theme: ButtonThemes.secondary,
  icon: 'filter_alt',
  svg: 'ico_nofilter',
  iconPosition: ButtonIconPosition.left,
};
export const invoiceMobileButton: IButtonConfig = {
  size: ButtonSize.s,
  theme: ButtonThemes.secondary,
  icon: '',
  iconPosition: ButtonIconPosition.left,
};
export const filterButton: IButtonConfig = {
  size: ButtonSize.s,
  theme: ButtonThemes.secondary,
  icon: 'filter_alt',
  svg: 'ico_nofilter',
  iconPosition: ButtonIconPosition.left,
};

export const casesAddButton: IButtonConfig = {
  size: ButtonSize.s,
  theme: ButtonThemes.primary,
  icon: 'add',
  iconPosition: ButtonIconPosition.left,
};

export const searchButton: IButtonConfig = {
  size: ButtonSize.s,
  theme: ButtonThemes.secondary,
  icon: 'search',
  iconPosition: ButtonIconPosition.left,
};

export const universeButtonConfig: IButtonConfig = {
  size: ButtonSize.m,
  theme: ButtonThemes.secondary,
  icon: 'keyboard_arrow_right',
  iconPosition: ButtonIconPosition.right,
};

export const testIconButton: IButtonConfig = {
  size: ButtonSize.s,
  theme: ButtonThemes.secondary,
  icon: 'search',
  iconPosition: ButtonIconPosition.left,
};
export const FiltersClearButton: IButtonConfig = {
  size: ButtonSize.l,
  theme: ButtonThemes.warn,
  icon: 'delete_outline',
  iconPosition: ButtonIconPosition.left,
};
export const uploadImage: IButtonConfig = {
  size: ButtonSize.s,
  theme: ButtonThemes.secondary,
  icon: '',
  iconPosition: ButtonIconPosition.left,
};
