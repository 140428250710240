import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class DirectusInterceptor implements HttpInterceptor {
  private readonly _accessToken: string = environment.directus.accessToken;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes(environment.directus.baseUrl)) return next.handle(request);
    const token = `Bearer ${this._accessToken}`;
    const requestWithToken = this._setBearerToken(token, request);
    return next.handle(requestWithToken);
  }

  private _setBearerToken(token: string, request: HttpRequest<unknown>): HttpRequest<unknown> {
    if (token) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        setParams: {
          access_token: this._accessToken,
        },
      });
    }
    return request;
  }
}
