import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { SortDirection } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';

import { IDataTable, IDisplayedColumn, ITableConfig } from './shared/interfaces/table.interface';
import { environment } from '../environments/environment';

// Services
import { AuthService } from './core/services/auth.service';
import { GtmService } from './shared/ports/gtm.service';
import { HeaderFooterVisibilityService } from './shared/services/header-footer-visibility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private readonly _defaultLanguage = 'es';
  public title = 'heinetCloudFront';
  public isHeaderAndFooterVisible = false;
  public tableConfig: ITableConfig = {
    showPaginator: true,
    totalElements: 20,
    pageNumber: 0,
    elements: 0,
    hidePageSizaOptions: false,
    showFirstLastButtons: true,
    pageSizeOptions: [5, 10, 25],
    pageSize: 10,
    initialPage: 0,
  };

  constructor(
    private _translate: TranslateService,
    public _authService: AuthService,
    private _router: Router,
    private _headerFooterVisibility: HeaderFooterVisibilityService,
    private _gtmService: GtmService
  ) {
    this._translate.setDefaultLang(this._defaultLanguage);
    this._translate.use(this._defaultLanguage);
    this._checkCurrentUser();
    this._setHeaderAndFooterVisible();
    // Google Analytics only used in production
    if (environment.env === 'prod') {
      this.publishAnalytics();
    }
  }

  private _checkCurrentUser() {
    const isResetPWD = window.location.href.includes('reset-password');
    const isLegal = window.location.href.includes('legal/');
    if (isResetPWD) {
      return;
    }
    this._authService.isValidUser().subscribe((isValidUser) => {
      if (!isValidUser) {
        if (isLegal) return;
        this._router.navigateByUrl('/');
      }
      this.isHeaderAndFooterVisible = true;
    });
  }

  publishAnalytics() {
    this._router.events.subscribe((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'navigate',
          pageName: item.url,
        };

        this._gtmService.sendEvent(gtmTag.event, gtmTag.pageName);
      }
    });
  }

  private _setHeaderAndFooterVisible(): void {
    this._headerFooterVisibility.headerAndFooterVisibilitySubject.subscribe((isVisible: boolean) => {
      this.isHeaderAndFooterVisible = isVisible;
    });
  }

  public handlerShowDetail(valor: IDataTable) {
    console.log(valor);
  }

  public handlerDataDeleted(valor: IDataTable) {
    console.log(valor);
  }

  public handlerDataEdited(valor: IDataTable) {
    console.log(valor);
  }

  public handlerTableSort(data: { rowHeaderData: IDisplayedColumn; order: SortDirection }) {
    this.tableConfig.filters = data;
  }

  public handlerChangePage(pageData: { elements: number; goToPage: number }) {
    this.tableConfig.pageNumber = pageData.goToPage;
    this.tableConfig.elements = pageData.elements;
  }
}
