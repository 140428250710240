<div class="field field__slider">
  <mat-label *ngIf="field.label && !field.hideLabel" class="field__label">{{ field.label }}</mat-label>
  <mat-slider class="slider" [min]="minValue" [max]="maxValue">
    <input matSliderStartThumb [(ngModel)]="currentMinValue" (change)="setValuesInForm()" />
    <input matSliderEndThumb [(ngModel)]="currentMaxValue" (change)="setValuesInForm()" />
  </mat-slider>
  <mat-hint *ngIf="field.hint" class="field__hint">{{ field.hint }}</mat-hint>

  <div class="slider__inputs">
    <div class="slider__input__group">
      <!-- FIXME: hardcoded text -->
      <mat-label class="slider__input__label"
        >{{ 'ERRORS.FORM.MINIMUM' | translate }} ({{ parseNumberToDots(minValue) }}€)</mat-label
      >
      <div class="slider__input">
        <input
          #inputMin
          class="input input--slider"
          type="number"
          [value]="currentMinValue"
          [ngModel]="currentMinValue"
          (ngModelChange)="currentMinValue = setMinValue($event)"
          (change)="setValuesInForm()"
        />
        <mat-icon matSuffix>euro_symbol</mat-icon>
      </div>
    </div>

    <div class="slider__input__group">
      <mat-label class="slider__input__label"
        >{{ 'ERRORS.FORM.MAXIMUM' | translate }} ({{ parseNumberToDots(maxValue) }}€)</mat-label
      >
      <div class="slider__input">
        <input
          #inputMax
          class="input input--slider"
          type="number"
          [value]="currentMaxValue"
          [ngModel]="currentMaxValue"
          (ngModelChange)="currentMaxValue = setMaxValue($event)"
          (change)="setValuesInForm()"
        />
        <mat-icon matSuffix>euro_symbol</mat-icon>
      </div>
    </div>
  </div>
</div>
