import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TableIconsService {
  public loadIcons(_matIconRegistry: MatIconRegistry, _domSanitizer: DomSanitizer): void {
    _matIconRegistry.addSvgIcon(
      'pencil-icon',
      _domSanitizer.bypassSecurityTrustResourceUrl('assets/images/pencil-icon.svg')
    );
    _matIconRegistry.addSvgIcon(
      'delete-user',
      _domSanitizer.bypassSecurityTrustResourceUrl('assets/images/delete-user.svg')
    );
    _matIconRegistry.addSvgIcon(
      'add-person-icon',
      _domSanitizer.bypassSecurityTrustResourceUrl('assets/images/add-person-icon.svg')
    );
    _matIconRegistry.addSvgIcon('ico_copy', _domSanitizer.bypassSecurityTrustResourceUrl('assets/images/ico_copy.svg'));
    _matIconRegistry.addSvgIcon('ico_info', _domSanitizer.bypassSecurityTrustResourceUrl('assets/images/ico_info.svg'));
    _matIconRegistry.addSvgIcon(
      'ico_info_outline',
      _domSanitizer.bypassSecurityTrustResourceUrl('assets/images/ico_info_outline.svg')
    );
    _matIconRegistry.addSvgIcon(
      'ico_download',
      _domSanitizer.bypassSecurityTrustResourceUrl('assets/images/ico_download.svg')
    );
    _matIconRegistry.addSvgIcon(
      'ico_rightArrow',
      _domSanitizer.bypassSecurityTrustResourceUrl('assets/images/ico_rightArrow.svg')
    );
    _matIconRegistry.addSvgIcon('ico_eye', _domSanitizer.bypassSecurityTrustResourceUrl('assets/images/ico_eye.svg'));
  }
}
