<form [formGroup]="formName" class="field field__input">
  <mat-label *ngIf="field.label && !field.hideLabel" [ngClass]="validation ? 'field__label' : 'field__labelWarning'">{{
    field.label
  }}</mat-label>
  <input
    [style.width]="width + '%'"
    [readonly]="field.disabled"
    [ngClass]="validation ? 'input input--text' : 'input input--text text--error'"
    [type]="field.type.value"
    [formControlName]="field.key"
    [placeholder]="field.placeholder ? field.placeholder : field.label"
    autocomplete="on"
    [maxlength]="maxLength || getMaxLength()"
    (blur)="validator()"
    (input)="validator()"
    (paste)="onPaste($event)"
  />
  <mat-hint *ngIf="field.hint" class="field__hint">{{ field.hint }}</mat-hint>
</form>
