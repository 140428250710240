export type ButtonThemeType = ButtonThemes.primary | ButtonThemes.secondary | ButtonThemes.phantom | ButtonThemes.warn;
export type ButtonSizeType = ButtonSize.s | ButtonSize.m | ButtonSize.l;
export type ButtonIconPositionType = ButtonIconPosition.left | ButtonIconPosition.right;

export enum ButtonThemes {
  primary = 'primary',
  secondary = 'secondary',
  phantom = 'phantom',
  warn = 'warn',
}

export enum ButtonSize {
  s = 'small',
  m = 'medium',
  l = 'large',
}

export enum ButtonIconPosition {
  left = 'left',
  right = 'right',
}
