import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth.interceptor';
import { DirectusInterceptor } from './directus.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { SuccessInterceptor } from './success.interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SuccessInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: DirectusInterceptor, multi: true },
];
