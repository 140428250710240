import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authGuard } from './core/guards/auth.guard';
import { devEnvironmentGuard } from './core/guards/dev-enviroment.guard';

const TITLE_INIT = 'HEINET';

const routes: Routes = [
  //---- Public routes
  {
    path: 'login',
    title: `${TITLE_INIT} - Login`,
    loadChildren: () => import('./domains/public/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'legal/:id',
    title: `${TITLE_INIT} - Legal`,
    loadChildren: () => import('./domains/public/legal/legal.module').then((m) => m.LegalModule),
  },
  {
    path: 'reset-password',
    title: `${TITLE_INIT} - Recuperar contraseña`,
    loadChildren: () =>
      import('./domains/public/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  //---- Private routes
  {
    path: 'customers-help',
    title: `${TITLE_INIT} - FAQ`,
    loadChildren: () => import('./domains/customers-help/customers-help.module').then((m) => m.CustomersHelpModule),
  },
  {
    path: 'invoices',
    title: `${TITLE_INIT} - Facturas`,
    loadChildren: () => import('./domains/billing/billing.module').then((m) => m.BillingModule),
    canActivate: [authGuard],
  },
  {
    path: 'dashboard',
    title: 'HEINET',
    loadChildren: () => import('./domains/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [authGuard],
  },
  {
    path: 'cases',
    title: `${TITLE_INIT} - Consultas`,
    loadChildren: () => import('./domains/cases/cases.module').then((m) => m.CasesModule),
    canActivate: [authGuard],
  },
  {
    path: 'profile',
    title: `${TITLE_INIT} - Perfil`,
    loadChildren: () => import('./domains/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [authGuard],
  },
  {
    path: 'heineken_universe',
    title: `${TITLE_INIT} - Universo HEINEKEN`,
    loadChildren: () =>
      import('./domains/heinken-universe/heinken-universe.module').then((m) => m.HeinkenUniverseModule),
    canActivate: [authGuard],
  },
  {
    path: 'users_management',
    title: `${TITLE_INIT} - Usuarios`,
    loadChildren: () =>
      import('./domains/users-management/users-management.module').then((m) => m.UsersManagementModule),
    canActivate: [authGuard],
  },
  {
    path: 'timeline',
    title: `${TITLE_INIT} - Ponte al día`,
    loadChildren: () => import('./domains/timeline/timeline.module').then((m) => m.TimelineModule),
    canActivate: [authGuard],
  },
  {
    path: 'components-integration',
    loadChildren: () =>
      import('./domains/componentsIntegration/components-integration.module').then(
        (m) => m.ComponentsIntegrationModule
      ),
    canActivate: [devEnvironmentGuard],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
