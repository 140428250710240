import { Component, Input, OnInit } from '@angular/core';
import { IArticleIframe } from '../../interfaces/article.interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
})
export class IframeComponent implements OnInit {
  @Input() props: IArticleIframe | null = null;

  public safeUrl: SafeResourceUrl = '';

  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.props) this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.props.src);
  }
}
