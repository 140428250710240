<form [formGroup]="formName" class="field field__select">
  <mat-label *ngIf="field.label && !field.hideLabel" class="field__label">{{ field.label }}</mat-label>
  <select class="input input--select" [formControlName]="field.key">
    <option value="" disabled selected hidden *ngIf="field.placeholder">{{ field.placeholder }}</option>
    <option class="select__option" *ngFor="let option of field.options" [value]="option.value">
      {{ option.label }}
    </option>
  </select>
  <mat-hint *ngIf="field.hint" class="field__hint">{{ field.hint }}</mat-hint>
</form>
