import { Clipboard } from '@angular/cdk/clipboard';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

// Angular material
import { MatIconRegistry } from '@angular/material/icon';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { IUser } from '../../interfaces/form.interface';
import { IDataTable, IDisplayedColumn, ITableConfig } from '../../interfaces/table.interface';
import { SidenavService } from '../../services/sidenav.service';
import { Roles } from '../../utils/enums/roles.model';
import { CellType, LinkType } from '../../utils/enums/table.model';
import { TableIconsService } from './table-icons.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  public readonly Roles = Roles;
  @Input() public loadTable!: boolean;
  @Input() public selectAllRows!: boolean;
  @Input() public displayedColumns!: IDisplayedColumn[];
  @Input() public dataTable!: IDataTable[];
  @Input() public tableConfig: ITableConfig = {
    showPaginator: true,
    totalElements: 0,
    hidePageSizaOptions: false,
    showFirstLastButtons: false,
    pageSizeOptions: [0],
    pageSize: 0,
    initialPage: 0,
    pageNumber: 0,
    elements: 0,
  };

  @Output() cellDetails = new EventEmitter<IDataTable>();
  @Output() disabledCellDetails = new EventEmitter<IDataTable>();
  @Output() dataTableDeleted = new EventEmitter<any>();
  @Output() eventRowEdited = new EventEmitter<IDataTable>();
  @Output() sortChangeEvent = new EventEmitter<{ rowHeaderData: IDisplayedColumn; order: SortDirection }>();
  @Output() selectedRowsEvent = new EventEmitter<IDataTable[]>();
  @Output() changePageEvent = new EventEmitter<{
    elements: number;
    goToPage: number;
  }>();
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  // FIXME: hardocded text
  public invoiceType = 'Saldo de envases';
  @ViewChild('tdElement', { static: false }) tdElement!: HTMLTableCellElement;
  @ViewChild('aElement', { static: false }) aElement!: HTMLElement;
  @ViewChild('columnSpan', { static: false }) columnSpan!: HTMLElement;

  public displayedColumnsTexts: string[] = [];
  public dataSource = new MatTableDataSource<any>([]);
  public dataSourceBackup: IDataTable[] = [];
  public cellType = CellType;
  public loadPaginatorLiterals = false;
  public sortDirection: SortDirection = 'asc';
  public selection = new SelectionModel<IDataTable>(true, []);
  public selectedRows: IDataTable[] = [];
  private _subscription: Subscription = new Subscription();
  private $formValuesObserver = new Subject<IUser>();

  constructor(
    private _router: Router,
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _clipboard: Clipboard,
    private _snackbar: MatSnackBar,
    private _iconsService: TableIconsService,
    private _sidenavService: SidenavService,
    private _cdr: ChangeDetectorRef
  ) {
    this.$formValuesObserver = this._sidenavService.$newDynamicFormValuesObserver;
    this._getIcons();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['dataTable']?.isFirstChange() && changes['dataTable']?.currentValue) {
      this.dataSource = new MatTableDataSource<any>(changes['dataTable']?.currentValue);
      this.table.renderRows();
      this._cdr.detectChanges();
    }
    if (changes['selectAllRows'] && !changes['selectAllRows'].isFirstChange()) {
      this.toggleAllRows();
      this._cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(this.dataTable);
    this.displayedColumnsTexts = this.displayedColumns.map((column) => column.text);
    this._sideNavActionsSubscribe();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this._cdr.detectChanges();
  }

  /**
   *
   * @param el Elemento del html que vamos comprobar para sacar el texto del campo
   * @param isHeader flag que indica si es un elemento del header de la tabla o del body
   * @returns true si el elemento es recortado por el ellipsis y se tiene que mostrar el tooltip informativo
   */
  // public isOverflow(el: HTMLElement | HTMLTableCellElement, isHeader = false): boolean {
  //   let htmlElement: HTMLElement | HTMLTableCellElement = el;
  //   if (isHeader) {
  //     htmlElement = this._getHtmlElement(el);
  //   }
  //   if (!htmlElement || !htmlElement.style) return false;
  //   const curOverflow = htmlElement.style.overflow;
  //   this._setOverflowInElement(curOverflow, htmlElement);
  //   const isOverflowing = this._calculateTextSpaceOnTableRow(htmlElement);
  //   htmlElement.style.overflow = curOverflow;

  //   return isOverflowing;
  // }
  // private _setOverflowInElement(curOverflow: string, htmlElement: HTMLElement | HTMLTableCellElement) {
  //   if (!curOverflow || curOverflow === 'visible') htmlElement.style.overflow = 'hidden';
  // }

  // private _calculateTextSpaceOnTableRow(htmlElement: HTMLElement | HTMLTableCellElement) {
  //   return htmlElement.clientWidth < htmlElement.scrollWidth || htmlElement.clientHeight < htmlElement.scrollHeight;
  // }

  public isOverflow(element: HTMLElement): boolean {
    return element.scrollWidth > element.clientWidth;
  }

  public updateSelectAllRows() {
    this.selectedRows = [];
    this.selection.clear();
    this.selectedRowsEvent.emit(this.selectedRows);
  }

  /**
   * @param el elemento del html sobre el que vamos. a mostrar el tootlip con el texto del overflow
   * @returns un elemento que contiene el texto recortado por el ellipsis dependiendo de el tipo de elemento html de la tabla
   */
  private _getHtmlElement(el: HTMLElement | HTMLTableCellElement): HTMLElement | HTMLTableCellElement {
    const htmlElement = <HTMLElement>el?.children[0]?.children[0]?.firstChild;
    const headerElement = el?.children[0]?.children[0];

    if (this._getTableHeaderElementInDashBoardWithoutIcon(headerElement)) {
      return <HTMLElement>headerElement.firstElementChild;
    } else if (this._getTableHeaderWithPlainText(headerElement)) {
      return <HTMLElement>el.children[0].children[0].children[0].firstElementChild;
    } else if (this._getTableHeaderWithInfoIcon(headerElement)) {
      return <HTMLElement>el?.children[0].children[0].children[0].children[1];
    }
    return htmlElement;
  }

  private _getTableHeaderElementInDashBoardWithoutIcon(headerElement: Element) {
    return headerElement?.className === 'header__infoicon';
  }

  private _getTableHeaderWithPlainText(headerElement: Element) {
    return headerElement?.children[0]?.children[0]?.classList[0] === 'ellipsis';
  }

  private _getTableHeaderWithInfoIcon(headerElement: Element) {
    return headerElement?.children[0]?.children[1]?.classList[0] === 'ellipsis';
  }

  private _sideNavActionsSubscribe() {
    this._subscription.add(
      this.$formValuesObserver.subscribe((user) => {
        this.table.renderRows();
      })
    );
  }

  public toggleAllRows() {
    this.selectedRows = [];
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.selectedRows = this.dataTable;
      this.selection.select(...this.dataSource.data);
    }
    this.selectedRowsEvent.emit(this.selectedRows);
  }

  public redirect(link: IDisplayedColumn['link'], valor: string): void {
    if (!link) return;
    if (link?.urlType === LinkType.INTERNAL) {
      this._navigateInternalUrl(valor, link);
      return;
    }
    if (link?.urlType === LinkType.EXTERNAL) {
      window.open(`${link.url}/${valor}`);
      return;
    }
    if (link?.urlType === LinkType.INTERMATTAB) {
      this._navigateInternalMatTabUrl(valor, link);
      return;
    }
    this._router.navigate([link?.url]);
  }

  private _navigateInternalUrl(valor: string, link: { urlType: string; url: string }): void {
    const id = this._getElementId(valor);
    if (id) {
      const url = `${link?.url}/${id}`;
      this._router.navigate([url]);
    }
  }

  private _navigateInternalMatTabUrl(valor: string, link: { urlType: string; url: string }): void {
    const url = `${link?.url}/${valor}`;

    this._router.navigateByUrl('/url', { skipLocationChange: true }).then(() => {
      // Navegar a la URL actual forzando la navegación
      this._router.navigateByUrl(url);
    });
  }

  private _getElementId(value: string): string {
    const row = this.dataTable.find((data: any) => data.requestNumber === value);
    return row?.id || '';
  }

  public viewDetails(rowData: IDataTable, action: string) {
    if (this._checkRowRole(rowData)) return;
    const rowDataAndAction = { ...rowData, ...{ action: action } };
    this.cellDetails.emit(rowDataAndAction);
  }

  public disabledDetails(rowData: IDataTable) {
    if (this._checkRowRole(rowData)) return;
    const rowDataAndAction = { ...rowData };
    this.disabledCellDetails.emit(rowDataAndAction);
  }

  public removeData(index: number) {
    const data = this.dataSource.data;
    // Actualizar el dataSource de la tabla
    const dataDeleted = data.splice(index, 1);
    if (!dataDeleted) {
      console.warn('error on delete row data');
      return;
    }
    const rowDataAndAction = { ...dataDeleted[0], ...{ action: 'delete' } };
    this.dataSource.data = data;

    this.dataTableDeleted.emit(rowDataAndAction);
    this.table.renderRows();
  }

  public setEditableData(index: number) {
    const data = this.dataSource.data;
    // Actualizar el dataSource de la tabla
    data[index].isEditable = !data[index].isEditable;
    this.dataSource.data = data;
    this.table.renderRows();
  }

  public getDataSourceBackUp() {
    this.dataSourceBackup = JSON.parse(JSON.stringify(this.dataSource.data));
  }

  public confirmEdition(index: number) {
    this.setEditableData(index);
    this.eventRowEdited.emit(this.dataSource.data[index]);
    console.log('Datos editados correctamente.');
  }

  public cancelEdition(index: number) {
    this.setEditableData(index);
    this.dataSource.data = this.dataSourceBackup;
    this.table.renderRows();
    console.log('Edicion cancelada.');
  }

  public onEdit(event: any, elementField: string, index: number) {
    const elementFieldEdited = event?.target?.textContent.trim();
    const data = this.dataSource.data;
    data[index][elementField] = elementFieldEdited;
    this.dataSource.data = data;
    this.table.renderRows();
  }

  public emitSort(order: Sort) {
    this.selectedRows = [];
    this.selection.clear();
    const selectedColum = this._getSelectedColum(order);
    if (selectedColum?.sortable?.sortType === 'external') {
      const prevOrder = selectedColum.sortable.order || 'desc';
      selectedColum.sortable.order = prevOrder == 'asc' ? 'desc' : 'asc';
      this.sortChangeEvent.emit({ rowHeaderData: selectedColum, order: prevOrder });
      return '';
    }
    return order.active;
  }

  /** The label for the checkbox on the passed row */
  public checkboxLabel(row?: IDataTable): string {
    if (!row || !row.position) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  public isAllSelected(): boolean {
    if (!this.dataSource) return false;
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows;
  }

  private _getSelectedColum(order: Sort): IDisplayedColumn | void {
    let selectedColum;
    this.displayedColumns.forEach((colum: IDisplayedColumn) => {
      if (colum.text === order.active) {
        selectedColum = colum;
      }
    });
    if (!selectedColum) {
      return;
    }
    return selectedColum;
  }

  /**
   * setSelectedRow
   * @description this method set row selected for publish
   * @param row select o unselect
   * @param isSelected status of checkbox in row
   */
  public setSelectedRow(row: IDataTable, isSelected: boolean) {
    if (isSelected) this.selectedRows.push(row);
    else if (!isSelected) this._unSelectRow(row);

    this.selectedRowsEvent.emit(this.selectedRows);
  }
  /**
   * @description delete of list rows unselected
   * @param row for delete on list of selected rows
   */
  private _unSelectRow(row: IDataTable) {
    this.selectedRows = this.selectedRows.filter((element: IDataTable) => element.id !== row.id);
  }

  /**
   * getSortDefault
   */
  public getSortDefault(): string {
    return this.displayedColumns[0].key;
  }

  public handlePageEvent(paginatorData: PageEvent) {
    this.selectedRows = [];
    this.selection.clear();
    const pageData = {
      elements: paginatorData.pageSize,
      goToPage: paginatorData.pageIndex,
    };
    this.changePageEvent.emit(pageData);
  }

  public handleCopyToClipboard(valor: string) {
    this._clipboard.copy(valor);
    this._snackbar.open('Copiado en el portapapeles', undefined, { duration: 500 });
  }

  private _getIcons(): void {
    this._iconsService.loadIcons(this._matIconRegistry, this._domSanitizer);
  }

  private _checkRowRole(row: IDataTable): boolean {
    if (row.role) {
      return row.role.toLowerCase() === 'gerente';
    }
    return false;
  }
}
