import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit {
  @Input() headerTitle!: string;
  @Input() length!: number;
  @Input() cardsVisible!: number;

  public cardCount: number = 0;
  public counter = 0;

  private readonly _gapBetweenCards: number = 8;
  private _cardWidth: number = 0;
  private _scrollableContainer: any;

  private _isDown = false;
  // Scroll when dragging
  private pos = { left: 0, x: 0, y: 0 };

  constructor(private _elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this._waitForCarouselCards();
  }

  private _waitForCarouselCards(): void {
    const observer = new MutationObserver(() => {
      // Get scrollable container to apply displacement
      const content = this._elementRef.nativeElement.querySelectorAll('#scrollableContent');
      if (content) {
        this._scrollableContainer = content[0];
        this._setEventListeners(this._scrollableContainer);
      }

      // Get one of the cards clientWidth to estimate displacement
      const cards = this._elementRef.nativeElement.querySelectorAll('mat-card');
      if (cards && cards.length > 0) {
        this.cardCount = cards.length + 1;
        const firstCard = cards[0].firstChild;

        if (firstCard) this._cardWidth = firstCard.clientWidth + this._gapBetweenCards;
        else console.error('No cards found. Review HTML skeleton');
        // Once mission accomplished - disconnect
        if (cards.length >= this.length) observer.disconnect();
      }
    });
    observer.observe(this._elementRef.nativeElement, { childList: true, subtree: true });
  }

  public moveLeft() {
    if (!this._scrollableContainer) return;
    this.counter--;
    this._scrollableContainer.scrollLeft -= this._cardWidth;
  }

  public moveRight() {
    if (!this._scrollableContainer) return;
    this.counter++;
    this._scrollableContainer.scrollLeft += this._cardWidth;
  }

  private _setEventListeners(element: any): void {
    element.addEventListener('mousedown', (e: any) => {
      this._isDown = true;
      this._setPosition(e, element.scrollLeft);
    });
    element.addEventListener('mouseleave', () => (this._isDown = false));
    element.addEventListener('mouseup', () => (this._isDown = false));
    element.addEventListener('mousemove', (e: any) => {
      if (!this._isDown) return;
      e.preventDefault();
      const dx = e.clientX - this.pos.x;
      element.scrollLeft = this.pos.left - dx;
    });
  }

  private _setPosition(e: MouseEvent, left: number) {
    this.pos = {
      // Current scroll
      left,
      // Current mouse position
      x: e.clientX,
      y: e.clientY,
    };
  }
}
