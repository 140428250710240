export enum gridKeys {
  NEWS = 'news',
  CASES = 'cases',
  INVOICES = 'invoices',
  ORDERS = 'orders',
  HEINEKEN_TOOLS = 'heinekenTools',
  OTHER_TOOLS = 'otherTools',
  LOGOUT = 'logout',
}

export enum positionAvailable {
  LEFT = 'left',
  RIGHT = 'right',
  FULL = 'full',
}
