import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  constructor(private _gtmService: GoogleTagManagerService) {}

  public sendEvent(eventName: string, params: any): void {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: eventName,
      data: params,
    };
    this._gtmService.pushTag(gtmTag);
  }
}
