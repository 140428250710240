import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IDialogData } from '../../interfaces/dialog.interface';
import { defaultButtonConfig, secondaryDefaultButtonConfig } from '../button/ui-button-configs/ui-button-config';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogComponent {
  public confirmButton = defaultButtonConfig;
  public cancelButton = secondaryDefaultButtonConfig;

  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IDialogData) {
    // console.log(data);
  }
}
