import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { take } from 'rxjs';

import { AuthService } from '../../../core/services/auth.service';
import { ITool } from '../../interfaces/tool.interface';
import { MainTools, OtherTools } from '../../utils/mocks/tools-list.mock';
import { Roles } from '../../utils/enums/roles.model';

@Component({
  selector: 'app-heineken-tools-list',
  templateUrl: './heineken-tools-list.component.html',
  styleUrls: ['./heineken-tools-list.component.scss'],
})
export class HeinekenToolsListComponent implements OnInit {
  @Input() others = false;
  public tools: ITool[] = MainTools;
  public otherTools: ITool[] = OtherTools;

  constructor(private _authService: AuthService, private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (!this._isLogisticRole()) this._getNautilustUrl();
    else {
      // Remove Nautilus from array
      this.tools = this.tools.filter((item) => item.title !== 'Nautilus');
    }
  }

  private _isLogisticRole(): boolean {
    return this._authService.getUserRol().includes(Roles.LOGISTICA);
  }

  public navigate(link: string): SafeUrl | void {
    if (!link) return;
    return this._sanitizer.bypassSecurityTrustUrl(link);
  }

  private _getNautilustUrl() {
    this._authService
      .getIdToken()
      .pipe(take(1))
      .subscribe((idToken) => this._setTokenInUrl(idToken));
  }

  private _setTokenInUrl(idToken: string): ITool[] {
    return this.tools.map((tool: ITool) => {
      if (tool.title === 'Nautilus' && tool.link.indexOf('?token') === -1) tool.link = `${tool.link}?token=${idToken}`;
      return tool;
    });
  }
}
