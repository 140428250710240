import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ICredentials } from '@aws-amplify/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { Observable, catchError, from, map, of } from 'rxjs';

import { Roles } from '../../shared/utils/enums/roles.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _userRol: string[] = [];
  private _userId = '';
  public hideHeaderAndFooter = false;

  constructor(private _router: Router) {}

  public isValidUser(): Observable<boolean> {
    return this._getCurrentSession().pipe(
      map((session) => {
        if (!this.isSignedInHostedUI()) return false;

        this._setUserRoles(session);
        const roles = this.getUserRol(session);
        this._getUserId(session);
        return this.isRoleAllowed(roles);
      }),
      catchError(() => of(false))
    );
  }

  /**
   *
   * @param roles actual user have
   * @returns true if the roles has a roll in allowed roles
   */

  public isRoleAllowed(roles: string[]): boolean {
    const isRoleAllowed = roles.some((role) => {
      const r = role.toLowerCase();
      const roleFound = Object.values(Roles).find((role: string) => role === r);
      return roleFound !== undefined && roleFound.length > 0;
    });
    return isRoleAllowed;
  }

  /**
   * @returns rol of actual user
   */
  public getUserRol(session?: CognitoUserSession): string[] {
    if (this._userRol) {
      return this._userRol;
    }
    return session?.getAccessToken()?.payload['cognito:groups'];
  }

  public getUserId(): string {
    return this._userId;
  }

  private _setUserRoles(session: CognitoUserSession): void {
    this._userRol = session.getAccessToken().payload['cognito:groups'];
  }

  /**
   * This method will automatically refresh the accessToken and idToken
   * if tokens are expired and a valid refreshToken presented.
   * @returns CognitoUserSession
   */
  private _getCurrentSession(): Observable<CognitoUserSession> {
    return from(Auth.currentSession());
  }

  public isSignedInHostedUI(): boolean {
    return localStorage.getItem('amplify-signin-with-hostedUI') === 'true';
  }

  /**
   * @description this method init flow for login a current user via AmplifyComponent
   */
  public startFederatedSignIn(): Promise<ICredentials> {
    return Auth.federatedSignIn();
  }

  /**
   * signOut
   * @description this method do the user logOut
   * @param global: boolean
   * @param redirectTo: string (url?) for redirect to specific web site
   */
  public signOut(global = true, redirectTo?: string): Promise<any> {
    console.log(global);
    return Auth.signOut({
      global,
    })
      .then((_) => console.log('logout', _))
      .catch(() => {
        console.error('error cerrando sesions');
        this._clearAllStorage();
        this._router.navigate(['login/auth-callback']);
      })
      .finally(() => console.log('fin cierre sesion'));
  }

  public backToLogin() {
    this._router.navigate(['login/auth-callback']);
  }

  private _clearAllStorage(): void {
    localStorage.clear();
    // Cookies
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      document.cookie = cookies[i] + '=; expires=' + new Date(0).toUTCString();
    }
    sessionStorage.clear();
  }

  /**
   * Returns idToken. If session is expired, the token is refreshed automatically
   */
  public getIdToken(): Observable<string> {
    return this._getCurrentSession().pipe(
      map((session) => {
        const idToken = session.getIdToken().getJwtToken();
        return idToken;
      })
    );
  }

  /**
   * @description This method changes the user's password.
   */
  public forgotPasswordSubmit(username: string, code: string, newPassword: string): Promise<string> {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  private _getUserId(session: CognitoUserSession): void {
    this._userId = session.getAccessToken().payload['username'];
  }

  public signIn(username: string, newPassword: string) {
    return Auth.signIn(username, newPassword);
  }
}
