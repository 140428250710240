<form [formGroup]="formName" class="field field__date">
  <mat-label *ngIf="field.label && !field.hideLabel" class="field__label">{{ field.label }}</mat-label>
  <div *ngIf="!field.range" class="datepicker">
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <input class="input input--date" [matDatepicker]="picker" [formControlName]="field.key" />
    <mat-datepicker-toggle class="input--date__toggle" matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </div>
  <!-- If range of dates, display two inputs & handle interaction -->
  <div *ngIf="field.range" class="datepicker">
    <mat-radio-group class="radio__options" aria-label="Select an option">
      <div class="radio__option datepicker__options" *ngFor="let option of radioOptionsForRange">
        <input
          class="option__input"
          type="radio"
          [id]="option.key"
          [value]="option.value"
          [checked]="selectedRadioButton === option.key"
        />
        <label class="option__label" [for]="option.label" (click)="saveSelectedRadioOption(option.key)">{{
          option.label
        }}</label>
      </div>
    </mat-radio-group>

    <div class="datepicker__input">
      <mat-date-range-input class="input input--date" [rangePicker]="picker">
        <input matStartDate placeholder="dd/mm/yy" (dateChange)="saveDateSelection($event.value, 0)" class="matStart" />
        <input matEndDate placeholder="dd/mm/yy" (dateChange)="saveDateSelection($event.value, 1)" class="matEnd" />
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </div>
  </div>
  <mat-hint *ngIf="field.hint" class="field__hint">{{ field.hint }}</mat-hint>
</form>
