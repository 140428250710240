export enum CellType {
  TEXT = 'text',
  LINK = 'link',
  LINKOPENINVOICES = 'linkOpenInvoices',
  EDIT = 'edit',
  EYE = 'eye',
  RESTORE = 'restore',
  MODIFY = 'modify',
  ACTIONS = 'actions',
  CHECKBOX = 'checkbox',
  VISUALPDF = 'visualpdf',
}

export enum LinkType {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
  INTERMATTAB = 'intermattab',
}

export enum SortType {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}
