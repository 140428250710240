import { Component } from '@angular/core';

@Component({
  selector: 'app-table-skeleton',
  templateUrl: './table-skeleton.component.html',
  styleUrls: ['./table-skeleton.component.scss'],
})
export class TableSkeletonComponent {
  public skeletonTableTheme = { height: '3.125rem', top: '0.313rem' };
  public displayedColumns: string[] = ['a', 'b', 'c', 'd'];

  public dataSource: object[] = [{}, {}, {}, {}];
}
