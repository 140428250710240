import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HasPermissionDirective } from '../directives/has-permission.directive';

/* Add directives to this array */
const sharedDirectives = [HasPermissionDirective];

@NgModule({
  declarations: [sharedDirectives],
  exports: [sharedDirectives],
  imports: [CommonModule],
})
export class DirectivesModule {}
