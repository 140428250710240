import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

import { IField } from '../../../../interfaces/form.interface';

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
})
export class SelectFieldComponent {
  @Input() field!: IField;
  @Input() width?: number = 70;
  formName: FormGroup;

  constructor(private _formGroupDirective: FormGroupDirective) {
    this.formName = _formGroupDirective.control;
  }
}
