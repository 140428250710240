import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthService } from '../../core/services/auth.service';
import { PermissionsConst } from '../utils/const/permissions-const';

@Directive({
  selector: '[hasPermission]',
})

/**
 * @description: this directive determines if the html element has been rendered by user rol
 */
export class HasPermissionDirective {
  private _userRol = '';
  private _permissionsByRol = PermissionsConst;

  constructor(
    private _templateRef: TemplateRef<HTMLElement>,
    private _viewContainer: ViewContainerRef,
    private _authService: AuthService
  ) {
    this._userRol = this._authService.getUserRol()[0];
  }

  @Input()
  set hasPermission(_domainTitle: string) {
    this._updateView(_domainTitle);
  }

  private _updateView(_domainTitle: string): void {
    this._userRol = this._authService.getUserRol()[0];
    if (this._checkPermission(_domainTitle)) {
      this._viewContainer.createEmbeddedView(this._templateRef);
      return;
    }
    this._viewContainer.clear();
  }

  private _checkPermission(_domainTitle: string): boolean {
    if (this._checkDirectiveImplementation(_domainTitle)) {
      console.warn('hasPermission bad implementation, please check entry params in directive or user roll');
      return false;
    }
    if (!this._permissionsByRol[this._userRol]) {
      console.warn('the rol don´t exist in the app');
      return false;
    }
    return this._permissionsByRol[this._userRol][_domainTitle];
  }

  private _checkDirectiveImplementation(_domainTitle: string): boolean {
    return !this._userRol || this._userRol === '' || !_domainTitle || _domainTitle === '';
  }
}
