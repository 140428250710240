import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map, throwError } from 'rxjs';

// Interfaces
import { IArticle, IAttachment, ILegalText } from '../../shared/interfaces/article.interface';
import { IChoice } from '../../shared/interfaces/dialog.interface';
import { IUniverseItem, IUniverseSubitem, IUniverseTab } from '../../shared/interfaces/universe.interface';

import { environment } from '../../../environments/environment';
import * as QueryFields from '../../shared/utils/const/directusQueryFields';

@Injectable({
  providedIn: 'root',
})
export class DirectusService {
  private readonly _graphURL = `${environment.directus.baseUrl}graphql`;
  private readonly _graphSystemURL = `${this._graphURL}/system`;

  constructor(private _http: HttpClient, private _translate: TranslateService) {}

  public getTimelineTypes(): Observable<string[]> {
    const query = `
      query getTimelineTypes{
        fields_by_name(collection: "ponte_al_dia", field: "type") {
          meta {
            options
          }
        }
      }`;

    const formattedQuery = this._formatGraphQLQuery(query);

    return this._http.post(this._graphSystemURL, formattedQuery).pipe(
      map((res: any) => {
        const choices = res.data.fields_by_name.meta.options.choices;
        return choices.map((choice: IChoice) => choice.value);
      }),
      catchError(() => throwError(() => 'Error fetching timeline types'))
    );
  }

  public getArticles(limit?: number): Observable<IArticle[]> {
    const setLimitParam = limit ? `limit: ${limit}` : '';

    const query = `
      query getArticles{
        ponte_al_dia(sort: ["-starred", "-date_created"],${setLimitParam}, filter: { status: { _eq: "published" } }){
          ${QueryFields.Article}
        }
      }`;

    const formattedQuery = this._formatGraphQLQuery(query);

    return this._http.post(this._graphURL, formattedQuery).pipe(
      map((res: any) => {
        const articles = res.data['ponte_al_dia'];
        articles.forEach((article: IArticle) => this._getArticleTranslations(article));
        return articles;
      }),
      catchError(() => throwError(() => 'Error fetching Ponte al día'))
    );
  }

  public getArticleById(id: number): Observable<IArticle> {
    const query = `
      query getArticleById{
        ponte_al_dia_by_id(id: ${id}){
          ${QueryFields.Article}
        }
      }`;

    const formattedQuery = this._formatGraphQLQuery(query);

    return this._http.post(this._graphURL, formattedQuery).pipe(
      map((res: any) => {
        const article = res.data['ponte_al_dia_by_id'];
        this._getArticleTranslations(article);
        return article;
      }),
      catchError(() => throwError(() => `Error fetching item ${id} of ponte_al_dia`))
    );
  }

  public getFileById(fileId: string): Observable<IAttachment> {
    const query = `
      query getFileById{
        files_by_id(id: "${fileId}") {
          ${QueryFields.File}
        }
      }`;

    const formattedQuery = this._formatGraphQLQuery(query);

    return this._http.post(this._graphSystemURL, formattedQuery).pipe(
      map((res: any) => res.data['files_by_id']),
      catchError(() => throwError(() => `Error fetching item ${fileId}`))
    );
  }

  public getLegalText(index: number): Observable<ILegalText> {
    const query = `
      query getLegalText{
        legal{
          ${QueryFields.Legal}
        }
      }`;
    const formattedQuery = this._formatGraphQLQuery(query);

    return this._http.post(this._graphURL, formattedQuery).pipe(
      map((res: any) => {
        const legal = res.data['legal'][index];
        this._getLegalTranslations(legal);
        return legal;
      }),

      catchError(() => throwError(() => 'Error fetching item of legal'))
    );
  }

  public getFAQ(): Observable<ILegalText[]> {
    const query = `
      query getFAQ{
        FAQ{
          ${QueryFields.FAQ}
        }
      }`;

    const formattedQuery = this._formatGraphQLQuery(query);

    return this._http.post(this._graphURL, formattedQuery).pipe(
      map((res: any) => res.data['FAQ']),
      catchError(() => throwError(() => 'Error fetching item of FAQ'))
    );
  }

  private _getArticleTranslations(article: IArticle) {
    return article.translations.map((translation) => {
      // Set content of article depending on current language
      if (translation.languages_code && translation.languages_code.code.includes(this._translate.currentLang)) {
        article.title = translation.title || '';
        article.body = translation.body || '';
        article.hashtags = translation.hashtags?.map((tag) => `#${tag}`) || [];
      }
    });
  }

  private _getLegalTranslations(legal: ILegalText) {
    return legal.translations.map((translation) => {
      // Set content of legal depending on current language
      if (translation.languages_code && translation.languages_code.code.includes(this._translate.currentLang)) {
        legal.title = translation.title || '';
        legal.content = translation.content || '';
      }
    });
  }

  /**
   * @description Removes linebreaks and indentations from a template string returning a simple string.
   */
  private _formatGraphQLQuery(query: string): string {
    const formattedQuery = query.replace(/\n/g, '').replace(/\s+/g, ' ').trim();
    return JSON.stringify({ query: formattedQuery });
  }

  // UNIVERSE HEINEKEN
  public getUniverseTabsTitles(): Observable<any[]> {
    const query = `
      query{
        universo_heineken{
          ${QueryFields.UniverseTabsTitles}
        }
      }`;

    const formattedQuery = this._formatGraphQLQuery(query);

    return this._http.post(this._graphURL, formattedQuery).pipe(
      map((res: any) => res.data['universo_heineken']),
      catchError(() => throwError(() => console.error('Error fetching universo_heineken titles')))
    );
  }

  public getUniverseTabsById(id: string): Observable<IUniverseTab> {
    const query = `
      query{
        universo_heineken_by_id(id: ${id}){
          ${QueryFields.UniverseTabs}
        }
      }`;

    const formattedQuery = this._formatGraphQLQuery(query);

    return this._http.post(this._graphURL, formattedQuery).pipe(
      map((res: any) => res.data['universo_heineken_by_id']),
      catchError(() => throwError(() => console.error(`Error fetching item ${id} of universo_heineken`)))
    );
  }

  public getUniverseTabItemById(id: string): Observable<IUniverseItem> {
    const query = `
      query{
        tab_item_by_id(id: ${id}){
          ${QueryFields.UniverseTabItem}
        }
      }`;

    const formattedQuery = this._formatGraphQLQuery(query);

    return this._http.post(this._graphURL, formattedQuery).pipe(
      map((res: any) => res.data['tab_item_by_id']),
      catchError(() => throwError(() => console.error(`Error fetching item ${id} of tab_item`)))
    );
  }

  public getUniverseTabSubitemById(id: string): Observable<IUniverseSubitem> {
    const query = `
      query GetSubitemById{
        subitem_by_id(id: ${id}){
          ${QueryFields.UniverseTabSubitem}
        }
      }`;

    const formattedQuery = this._formatGraphQLQuery(query);

    return this._http.post(this._graphURL, formattedQuery).pipe(
      map((res: any) => res.data['subitem_by_id']),
      catchError(() => throwError(() => console.error(`Error fetching item ${id} of subitem`)))
    );
  }
}
