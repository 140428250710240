import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { IAlert, ITranslation } from '../../interfaces/alerts.interface';
import { AlertsService } from '../../ports/alerts-http.service';
import { SidenavService } from '../../services/sidenav.service';
import { AlertTypeLinks } from '../../utils/const/alert-const';
import { calculateElapsedTimeFromNow, isElementInViewport } from '../../utils/utils';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit {
  public alerts: IAlert[] = [];
  public numSelectedAlerts = 0;
  public readPending = 0;
  public allSelected = false;
  public pages = 0;
  public endOfPagination = false;
  public handleWheelEvent: any;
  public isDrawerOpen = false;

  private _isDrawerOpenSubscription: Subscription = new Subscription();
  private _isAllSelected = false;

  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _translate: TranslateService,
    private _alertsService: AlertsService,
    private _sidenav: SidenavService,
    private _router: Router
  ) {}

  ngOnInit() {
    this._loadIcons();
    this._retrieveAlerts();
    this._activateOnWheelListener();
  }

  ngOnDestroy() {
    this._isDrawerOpenSubscription.unsubscribe();
    window.removeEventListener('wheel', this.handleWheelEvent);
  }

  private _activateOnWheelListener() {
    this.handleWheelEvent = this.lastElement.bind(this);
    this._isDrawerOpenSubscription = this._sidenav.isDrawerOpen.subscribe((opened: boolean) => {
      this.isDrawerOpen = opened;
      if (opened) {
        window.addEventListener('wheel', this.handleWheelEvent);
      } else {
        window.removeEventListener('wheel', this.handleWheelEvent);
      }
    });
  }

  private _retrieveAlerts() {
    if (this.endOfPagination) return;
    this._alertsService.getAlerts(this.pages).subscribe((alerts: IAlert[]) => {
      if (alerts.length <= 0) {
        this.endOfPagination = true;
      }
      this.alerts.push(...alerts);
      if (this.allSelected) alerts.map((alert: IAlert) => (alert.selected = true));
      this._initializeAlerts();
    });
  }

  public lastElement() {
    const alerts = document.getElementById('alertSection');
    if (!alerts) return;

    const lastChild = alerts.children[alerts.children.length - 1];
    if (isElementInViewport(lastChild)) {
      this._incrementPage();
    }
  }

  private _incrementPage() {
    this.pages++;
    this._retrieveAlerts();
  }

  private async _initializeAlerts() {
    this._translate.get('ALERTS.TYPES').subscribe((translates: ITranslation) => {
      this.alerts.forEach((alert: IAlert) => {
        this._setLink(alert);
        this._setElapsedTimeSinceAlert(alert);
        this._setAlertTypeTranslations(alert, translates);
        if (!alert.read) this.readPending++;
      });
    });
  }

  /**
   * Depending on the alert type (type), alert must redirect to corresponding tab
   */
  private _setLink(alert: IAlert): void {
    const linkData = AlertTypeLinks[alert.type];
    if (!linkData) return;

    this._translate.get(linkData.text).subscribe((translation: string) => {
      linkData.text = translation;
      alert.link = linkData;
    });
  }

  private _setElapsedTimeSinceAlert(alert: IAlert): void {
    // Hours elapsed since the alert was created
    const elapsedHours = calculateElapsedTimeFromNow(alert.createDate);

    this._translate.get('ALERTS.TIMES').subscribe((translates: ITranslation) => {
      const hours = Math.trunc(elapsedHours);
      if (hours >= 1) {
        if (hours >= 24 && hours <= 48) alert.time = translates['YESTERDAY']; // Add 'Yesterday' tag
        if (hours > 48) {
          const days = Math.trunc(hours / 24);
          alert.time = `${translates['AGO']} ${days} ${translates['DAYS']}`;
        } else alert.time = `${translates['AGO']} ${hours} ${translates['HOURS']}`;
        return;
      }
      const minutes = Math.trunc(elapsedHours * 60);
      if (minutes < 5) {
        alert.time = translates['NOW']; // Add 'Now' tag
      } else alert.time = `${translates['AGO']} ${minutes} ${translates['MINUTES']}`;
    });
  }

  private _setAlertTypeTranslations(alert: IAlert, translates: ITranslation) {
    if (!alert.type) return;

    const type = alert.type.toUpperCase();
    if (translates[type]) alert.type = translates[type];
  }

  private _loadIcons(): void {
    this._matIconRegistry.addSvgIcon(
      'ico-delete',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/ico_delete_outline.svg')
    );
  }

  private _getInvoiceNumberInvoice(content: string): string {
    const numeroFacturaRegex = /\d+[A-Za-z]*\s*\d*/;
    const number = content.match(numeroFacturaRegex);
    if (number && number.length > 0) {
      return number[0].trim();
    }
    return '';
  }

  public markSelectedAsRead(): void {
    // FIXME: Sacar a función
    // Get ids of selected alerts
    if (this._isAllSelected) {
      this._alertsService.readAllAlerts().subscribe(() => {
        this._alertsService.updateUnreadAlerts();
        this.readPending = 0;
        this.allSelected = false;

        this.alerts.map((alert: IAlert) => {
          this.selectAlert(alert);
          alert.read = true;
        });
      });
    } else {
      const selected = this.alerts.filter((alert) => alert.selected);
      if (selected && selected.length > 0) {
        const ids = selected.map((alert) => alert.id);

        this._alertsService.readMultipleAlerts(ids).subscribe(() => {
          selected.map((alert) => {
            this.selectAlert(alert);
            alert.read = true;
          });
          this.readPending -= selected.length;
          this.allSelected = false;
          this._alertsService.updateUnreadAlerts();
        });
      }
    }
  }

  /**
   * Checks if any of the selected alerts is still unread
   */
  public isAnySelectedUnread(): boolean {
    return this.alerts.some((alert: IAlert) => alert.selected && !alert.read);
  }

  public deleteSelected(): void {
    // Get ids of selected alerts
    if (this._isAllSelected) {
      this._alertsService.deleteAllAlerts().subscribe(() => {
        this._alertsService.updateUnreadAlerts();
        this.alerts = [];
      });
    } else {
      const selected = this.alerts.filter((alert) => alert.selected).map((alert) => alert.id);
      if (selected && selected.length > 0) {
        this._alertsService.deleteMultipleAlerts(selected).subscribe(() => {
          this.alerts = [];
          this._retrieveAlerts();
          this._alertsService.updateUnreadAlerts();
        });
      }
    }
  }

  public deleteAlert(alert: IAlert): void {
    this._alertsService.deleteAlert(alert.id).subscribe(() => {
      const alertPosition = this.alerts.indexOf(alert);
      if (!alert.read) this._alertsService.updateUnreadAlerts();
      if (alertPosition > -1) {
        this.alerts.splice(alertPosition, 1);
      }
    });
  }

  public selectAllAlerts(): void {
    this._isAllSelected = !this._isAllSelected;
    if (this.alerts.length <= 0) return;

    this.allSelected = !this.allSelected;
    this.numSelectedAlerts = this.allSelected ? this.alerts.length : 0;
    this.alerts.forEach((alert: IAlert) => (alert.selected = this.allSelected));
  }

  public selectAlert(alert: IAlert): void {
    alert.selected = !alert.selected;
    // If selected, increment numSelectedAlert, if not, decrement by adding -1
    this.numSelectedAlerts += alert.selected ? 1 : -1;
  }

  // FIXME: dejar el type sin cambiar y maquetar en el html o con una función que devuleve el valor
  public redirect(alert: IAlert): void {
    const { type, content, url } = alert;

    if (type === 'Facturas') {
      this._router.navigate(['invoices'], { queryParams: { invoiceNumber: this._getInvoiceNumberInvoice(content) } });
    } else if (type === 'Consultas') {
      this._router.navigate([`cases/case/${url}`]);
    } else if (type === 'Ponte al día') {
      this._router.navigate([`timeline/article/${url}`]);
    }
    this._sidenav.$openSidenavObserver.next('');
  }
}
