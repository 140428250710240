<div class="form">
  <h1 class="form__title" *ngIf="modelForm.title" [innerHTML]="modelForm.title">{{ modelForm.title }}</h1>

  <form class="form--dynamic" [formGroup]="dynamicFormGroup" (ngSubmit)="onSubmit()">
    <div class="form__fields">
      <div *ngFor="let field of displayedFields">
        <app-dynamic-field
          [accordion]="modelForm.accordion || false"
          [field]="field"
          [width]="hasHiddenFields() ? 100 : 70"
        ></app-dynamic-field>
        <app-dynamic-error [key]="field.key" [label]="field.label"></app-dynamic-error>
        <app-warning *ngIf="field.alert && !field.hide" [alert]="field.alert"></app-warning>
      </div>
    </div>

    <div class="form__buttons">
      <div class="form__button" *ngFor="let button of modelForm.buttons">
        <!-- If button is of type submit, do not set (click) method for the onSubmit() to be made -->
        <app-button
          *ngIf="button.type === 'submit'"
          [buttonConfig]="button.config"
          [type]="button.type"
          [disabled]="!formValidator()"
          >{{ button.text }}</app-button
        >
        <app-button
          *ngIf="button.type !== 'submit'"
          [buttonConfig]="button.config"
          [type]="button.type"
          (click)="closeDrawer()"
          >{{ button.text }}</app-button
        >
      </div>
    </div>
  </form>
</div>
