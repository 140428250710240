import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

import { IField, IFieldOption } from '../../../../interfaces/form.interface';

@Component({
  selector: 'app-slider-field',
  templateUrl: './slider-field.component.html',
  styleUrls: ['./slider-field.component.scss'],
})
export class SliderFieldComponent {
  @ViewChild('inputMin') inputMinRef!: ElementRef;
  @ViewChild('inputMax') inputMaxRef!: ElementRef;

  @Input() field!: IField;
  @Input() width?: number = 70;
  formName: FormGroup;

  private readonly _numberBase = 10;
  private readonly _numberLocaleFormat = 'en-ZA'; // corresponding to yyyy/mm/dd format

  public maxValue = -1000000; // default value
  public minValue = 1000000;

  public defaultMinValue = 0;
  public defaultMaxValue = 100000;

  public currentMinValue: number = this.defaultMinValue;
  public currentMaxValue: number = this.defaultMaxValue;

  public validInput = true;

  ngOnInit(): void {
    this._handleFieldOptions();
  }

  constructor(private _formgroupDirective: FormGroupDirective) {
    this.formName = this._formgroupDirective.control;
  }

  private _handleFieldOptions(): void {
    if (this.field.options && this.field.options.length > 0) {
      // Get min and max values
      const min = this.field.options.find((option: IFieldOption) => option.key === 'amountFrom');
      const max = this.field.options.find((option: IFieldOption) => option.key === 'amountTo');
      if (min) {
        this.minValue = parseInt(min.value, this._numberBase);
        this.defaultMinValue = parseInt(min.label, this._numberBase);
        this.currentMinValue = this.defaultMinValue;
      }
      if (max) {
        this.maxValue = parseInt(max.value, this._numberBase);
        this.defaultMaxValue = parseInt(max.label, this._numberBase);
        this.currentMaxValue = this.defaultMaxValue;
      }
    }
  }

  public setValuesInForm(): void {
    const prev = this.formName.value;
    // Save selected values
    prev[this.field.key] = [this.currentMinValue, this.currentMaxValue];
    this.formName.setValue(prev);
  }

  public parseNumberToDots(number: number): string {
    return number.toLocaleString(this._numberLocaleFormat);
  }

  public valueBetweenMinMax(value: number) {
    return Math.min(Math.max(value, this.minValue), this.maxValue);
  }

  public setMaxValue(value: number): number {
    const input = this.valueBetweenMinMax(value);
    this.inputMaxRef.nativeElement.value = input;
    return input;
  }

  public setMinValue(value: number): number {
    const input = this.valueBetweenMinMax(value);
    this.inputMinRef.nativeElement.value = input;
    return input;
  }
}
