import { Component, EventEmitter, Output } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';

import { ProfileHttpService } from '../../ports/profile-http.service';
import { convertToBase64 } from '../../utils/base64/encodeBase64';
import { uploadImage } from '../button/ui-button-configs/ui-button-config';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
})
export class BottomSheetComponent {
  @Output() imageUploadedEmit: EventEmitter<any> = new EventEmitter<any>();
  public selectedFile: File | null = null;
  public imgcropper = false;
  public imgBase64 = '';
  public imgCaptured = '';
  public errorMessage: string | null = null;
  public buttonConfigs = {
    caseButton: uploadImage,
  };

  constructor(
    private _translate: TranslateService,
    private _profileHttpService: ProfileHttpService,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>
  ) {}

  // FIXME: SACAR A UN SERVICIO
  private _sendPostRequest(base64Data: string): void {
    const requestData = {
      image: base64Data,
    };
    this._profileHttpService.updateImage(requestData.image).subscribe();
  }

  public openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  public onFileSelected(event: any): void {
    this.imgcropper = false;
    const file: File = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      this.selectedFile = file;
      const reader = new FileReader();
      reader.onload = () => {
        const base64String: string = reader.result as string;
        this.imgBase64 = base64String;
        this.errorMessage = null;
        this.imgcropper = true;
      };
      reader.readAsDataURL(file);
      return;
    }

    this.selectedFile = null;
    this._translate.get('PROFILE.PROFILE_PHOTO').subscribe((translates) => {
      this.errorMessage = translates.ERROR_MESSAGE;
    });
  }

  public setImgCaptured(e: any) {
    this.imgCaptured = e;
  }

  private _changeImgCapturedToFile(img: string): File {
    const byteCharacters = window.atob(img.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    // Crea un nuevo objeto File a partir del array de bytes
    const file = new File([byteArray], 'imgCropped.png', { type: 'image/png' });
    return file;
  }

  public uploadImage(): void {
    if (this.imgCaptured) {
      convertToBase64(this._changeImgCapturedToFile(this.imgCaptured))
        .then((base64Data) => {
          this._sendPostRequest(base64Data);
          this.imageUploadedEmit.emit('Subida realizada con éxito.');
        })
        .catch((error) => {
          console.error('Error al convertir a base64:', error);
        })
        .finally(() =>
          setTimeout(() => {
            location.reload();
          }, 2000)
        );
    }
    this._bottomSheetRef.dismiss();
  }

  public isFormValid(): boolean {
    return !!this.selectedFile || !!this.errorMessage;
  }
}
