import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

import { IField } from '../../../../interfaces/form.interface';

@Component({
  selector: 'app-textarea-field',
  templateUrl: './textarea-field.component.html',
  styleUrls: ['./textarea-field.component.scss'],
})
export class TextareaFieldComponent {
  @Input() field!: IField;
  @Input() width?: number = 70;
  formName: FormGroup; // Tracks the value and validity state of a group of FormControl instances.
  validation = true;

  constructor(private _formgroupDirective: FormGroupDirective) {
    this.formName = this._formgroupDirective.control;
  }

  public validator() {
    this.validation =
      this.formName.controls[this.field.key].status !== 'INVALID' ||
      (!this.formName.controls[this.field.key].touched && !this.formName.controls[this.field.key].dirty);
  }
}
