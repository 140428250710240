import { Component, Input, OnDestroy, OnInit, Type, ViewChild, AfterViewInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

import { IForm } from '../../interfaces/form.interface';
import { SidenavService } from '../../services/sidenav.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() openDrawer: any;
  @ViewChild(MatDrawer) drawer!: MatDrawer;

  public modelForm: IForm | null = null;
  public component: Type<any> | null = null;
  public isDrawerOpen = false;
  public sharedService: any;

  private _subscription: Subscription = new Subscription();
  private $sidenavSubject: BehaviorSubject<any>;

  constructor(private _sidenavService: SidenavService) {
    this.$sidenavSubject = this._sidenavService.$openSidenavObserver;
  }

  ngOnInit(): void {
    this._subscribeSideNavChanges();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.drawer.openedChange.subscribe((opened: boolean) => {
      this._sidenavService.setIsDrawerOpen(opened);
    });
  }

  private _subscribeSideNavChanges() {
    this._subscription.add(
      /**
       * @param model > puede ser un formulario o un componente
       */
      this.$sidenavSubject.subscribe((model: any) => {
        this._resetState();
        // Control if input is a IForm or an entire Component
        if (this._isForm(model)) {
          this.modelForm = model;
          this.component = null;
        } else {
          this.component = model;
          this.modelForm = null;
        }
        if (this.drawer) this.drawer.toggle();
      })
    );
  }

  private _resetState(): void {
    this.modelForm = null;
    this.component = null;
  }

  public addUser(user: any) {
    this._sidenavService.$newDynamicFormValuesObserver.next(user);
  }

  private _isForm(object: any): boolean {
    return object !== null && typeof object === 'object' && typeof object.title === 'string';
  }
}
