<!-- HEINEKEN TOOLS -->
<div class="tools__container tools__container--main" *ngIf="!others">
  <mat-card class="tool__card" *ngFor="let tool of tools">
    <a [href]="navigate(tool.link)" target="_blank">
      <div class="tool__card__body">
        <img
          *ngIf="tool.logo"
          class="body__img"
          [src]="tool.logo"
          [alt]="tool.title"
          alt="icon with image of external app"
        />
        <div class="body__text">
          <img *ngIf="tool.title.includes('assets/')" class="image__as__title" [src]="tool.title" alt="" />
          <h5 *ngIf="!tool.title.includes('assets/')" class="body__title">{{ tool.title }}</h5>
          <p class="summary">{{ tool.summary }}</p>
        </div>
        <img src="/assets/images/link_icon.svg" alt="enlace" />
      </div>
    </a>
  </mat-card>
</div>

<!-- OTHER TOOLS -->
<div class="tools__container tools__container--others" *ngIf="others">
  <div class="tool__container" *ngFor="let tool of otherTools">
    <a [href]="navigate(tool.link)" target="_blank">
      <div class="other__tool">
        <img [src]="tool.logo" [alt]="tool.title" alt="icon with image of external app" />
        <img src="/assets/images/link_icon.svg" alt="enlace" />
      </div>
    </a>
  </div>
</div>
