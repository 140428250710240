import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OpenCaseFormService {
  // Initialize as false to prevent first openning when loading component
  public $openCreateDrawerObserver = new BehaviorSubject<boolean>(false);

  private constructor(private _router: Router) {}

  public openCreateCaseForm(caseSubtype: string): void {
    this._router.navigate(['cases'], { queryParams: { create: caseSubtype } }).then(() => {
      this.$openCreateDrawerObserver.next(true);
    });
  }
}
