import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Auth } from 'aws-amplify';
import { environment } from './environments/environment';

Auth.configure({
  Auth: {
    region: environment.amplify.region,
    userPoolId: environment.amplify.userPoolId,
    userPoolWebClientId: environment.amplify.userPoolWebClientId,
    oauth: {
      scope: environment.amplify.oauth.scope,
      domain: environment.amplify.oauth.identityDomain,
      redirectSignIn: environment.amplify.oauth.identityRedirectSignIn,
      redirectSignOut: environment.amplify.oauth.identityRedirectSignOut,
      responseType: environment.amplify.oauth.responseType,
    },
  },
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
