<mat-card class="attachment" *ngIf="fileData">
  <mat-card-content class="attachment__content">
    <h6 class="content__title">{{ fileData.title }}</h6>
    <img *ngIf="isPreviewAvailable(fileData.type)" class="content__preview preview--file" [src]="fileURL" alt="" />
    <img
      *ngIf="!isPreviewAvailable(fileData.type)"
      class="content__preview preview--placeholder"
      [src]="defaultPlaceholder"
      alt=""
    />
    <div class="content__details">
      <p class="content__size">{{ fileData.filesize }} MB</p>
      <p class="content__type">{{ fileData.type }}</p>
    </div>
    <a
      *ngIf="!isPreviewAvailable(fileData.type) && isPDFExtension(fileData.type)"
      class="button--download"
      mat-flat-button
      (click)="openPDF(fileURL)"
    >
      <mat-icon>remove_red_eye</mat-icon>
      {{ 'ATTACHMENT.VIEW' | translate }}</a
    >
    <a
      *ngIf="!isPreviewAvailable(fileData.type)"
      class="button--download"
      mat-flat-button
      [href]="fileURL + '?download'"
      [download]="fileData.filename_download"
    >
      <mat-icon>get_app</mat-icon>
      {{ 'ATTACHMENT.DOWNLOAD' | translate }}</a
    >
    <a
      class="button--download"
      mat-flat-button
      *ngIf="isPreviewAvailable(fileData.type)"
      (click)="downloadImage(fileData, fileURL)"
    >
      <mat-icon>get_app</mat-icon>
      {{ 'ATTACHMENT.DOWNLOAD' | translate }}</a
    >
  </mat-card-content>
</mat-card>
