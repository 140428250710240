<div class="search__container">
  <input
    class="input"
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="searchValue"
    (keyup.enter)="handleSearch()"
  />
  <app-button [buttonConfig]="searchButtonConfig" (click)="handleSearch()"></app-button>
</div>
