import {
  InputFieldComponent,
  CheckboxFieldComponent,
  RadioFieldComponent,
  SelectFieldComponent,
  SliderFieldComponent,
  DateFieldComponent,
  TextareaFieldComponent,
} from '../../components/dynamic-field/field-types';

export const availableTypes = {
  color: {
    value: 'color',
    template: InputFieldComponent,
  },
  date: {
    value: 'date',
    template: DateFieldComponent,
  },
  datetimeLocal: {
    value: 'datetime-local',
    template: InputFieldComponent,
  },
  email: {
    value: 'email',
    template: InputFieldComponent,
  },
  month: {
    value: 'month',
    template: InputFieldComponent,
  },
  number: {
    value: 'number',
    template: InputFieldComponent,
  },
  password: {
    value: 'password',
    template: InputFieldComponent,
  },
  search: {
    value: 'search',
    template: InputFieldComponent,
  },
  tel: {
    value: 'tel',
    template: InputFieldComponent,
  },
  text: {
    value: 'text',
    template: InputFieldComponent,
  },
  textarea: {
    value: 'textarea',
    template: TextareaFieldComponent,
  },
  time: {
    value: 'time',
    template: InputFieldComponent,
  },
  url: {
    value: 'url',
    template: InputFieldComponent,
  },
  week: {
    value: 'week',
    template: InputFieldComponent,
  },
  slider: {
    value: 'slider',
    template: SliderFieldComponent,
  },
  checkbox: {
    value: 'checkbox',
    template: CheckboxFieldComponent,
  },
  radio: {
    value: 'radio',
    template: RadioFieldComponent,
  },
  select: {
    value: 'select',
    template: SelectFieldComponent,
  },
};

export const availableRules = {
  required: 'required',
  email: 'email',
  equalTo: 'equalTo',
  pattern: 'pattern',
  maxLength: 'maxlength',
};
