<div
  class="container__emptyData"
  [ngClass]="emptyData.template === 'small' ? 'container__emptyData--small' : 'container__emptyData--big'"
  [class.container__emptyData--dashboard]="isInDashboard"
>
  <img class="img__emptyData" src="{{ emptyData.img }}" alt="gestion de usuarios" />
  <h2 *ngIf="emptyData.template === 'big'">{{ emptyData.title }}</h2>
  <h5 *ngIf="emptyData.template === 'small'">{{ emptyData.title }}</h5>
  <p>{{ emptyData.body }}</p>
  <app-button
    *ngIf="emptyData.buttonText"
    [buttonConfig]="emptyData.buttonConfig!"
    (click)="handleButtonClicked($event)"
    >{{ emptyData.buttonText }}</app-button
  >
</div>
