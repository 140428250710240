import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MaterialModule } from './shared/modules/material.module';
import { NgxTranslateModule } from './shared/modules/ngx-translate.module';

import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from '../environments/environment';
import { httpInterceptorProviders } from './core/interceptor/http-interceptor-providers';
import { ComponentsModule } from './shared/components/components.module';
import { DirectivesModule } from './shared/directives/directives.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgxTranslateModule,
    BrowserAnimationsModule,
    MaterialModule,
    DirectivesModule,
    ComponentsModule,
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManager.gtmId,
    }),
  ],
  exports: [MaterialModule, DirectivesModule, ComponentsModule, AppRoutingModule],
  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
