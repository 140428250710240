export const Article = `
  id
  status
  sort
  date_created
  type
  starred
  translations{
    id
    hashtags
    title
    body
    languages_code{
      code
    }
  }
  header {
    id
  }
  referencia_img
  attached_files{
    directus_files_id{
      id
    }
  }
`;

export const File = `
  id
  filename_download
  title
  type
  uploaded_on
  modified_on
  filesize
  embed
  description
`;

export const UniverseTabsTitles = `
  id
  title
  `;

export const UniverseTabs = `
  id
  title
  description
  tab_content {
    id
    title
    logo{
      id
    }
    subitems{
      id
    }
  }
  disclaimer
`;

export const UniverseTabItem = `
  id
  title
  description
  subitems{
    id
    title
    logo{
      id
    }
  }
`;

export const UniverseTabSubitem = `
  id
  title
  description
  url
  background_hero {
    id
  }
  referencia_img
  content_types{
    id
    tipo
    links
    attached{
      directus_files_id{
        id
        title
      }
    }
  }
  `;

export const Legal = `
  id
  translations{
    id
    languages_code{
      code
    }
    title
    content
  }
`;

export const FAQ = `
  id
  title
  body
`;
