import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

import { IField, IFieldOption } from '../../../../interfaces/form.interface';
import { getDateParsed } from '../../../../utils/utils';
import { DateRangeOptions } from '../../../../utils/const/date-const';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MM YY',
    monthYearLabel: 'DD MM YY',
    dateA11yLabel: 'DD MM YY',
    monthYearA11yLabel: 'DD MM YY',
  },
};

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DateFieldComponent implements OnInit {
  @Input() field!: IField;
  @Input() width?: number = 70;
  formName: FormGroup;

  private readonly _dayToMilliseconds: number = 24 * 60 * 60 * 1000;
  private readonly _numberLocaleFormat = 'en-CA'; // corresponding to yyyy/mm/dd format

  public selectedRadioButton = '';
  public radioOptionsForRange: IFieldOption[] = [];
  public selectedDates: string[] = ['', ''];

  constructor(private _formgroupDirective: FormGroupDirective) {
    this.formName = this._formgroupDirective.control;
  }

  ngOnInit(): void {
    this.radioOptionsForRange = this._createRadioOptionsForRange(DateRangeOptions);
  }

  public saveSelectedRadioOption(optionKey: string): void {
    this.selectedRadioButton = optionKey;
    let startingDate: Date | null = null;

    const currentTime = new Date();
    if (optionKey.includes('week')) {
      // Remove current week days to set to beginning of the week
      startingDate = new Date(Date.now() - currentTime.getDay() * this._dayToMilliseconds);
      // Calcula la fecha del domingo de esta semana
      const endOfWeek = new Date(startingDate.getTime() + 6 * this._dayToMilliseconds);
      this.selectedDates = [startingDate?.toString() || '', endOfWeek.toString()];
      this._setValuesInForm();
      return;
    }
    if (optionKey.includes('month')) {
      // Set startingDate to the beginning of the month
      startingDate = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1);
      // Calculate the date of the last day of the month
      const endOfMonth = new Date(currentTime.getFullYear(), currentTime.getMonth() + 1, 0);
      this.selectedDates = [startingDate?.toString() || '', endOfMonth.toString()];
      this._setValuesInForm();
      return;
    }
    if (optionKey.includes('year')) {
      // Set to first day of first month of the same year
      startingDate = new Date(currentTime.getFullYear(), 0, 1);
      const endOfYear = new Date(currentTime.getFullYear(), 11, 31);
      this.selectedDates = [startingDate?.toString() || '', endOfYear.toString()];
      this._setValuesInForm();
      return;
    }
  }

  public saveDateSelection(date: string, index: number): void {
    this.selectedDates[index] = date;
    this._setValuesInForm();
  }

  /**
   * Modify radio option's key to a unique string by concatenating the field key
   */
  private _createRadioOptionsForRange(options: IFieldOption[]): IFieldOption[] {
    const radioOptions = JSON.parse(JSON.stringify(options)); // Safe copy of options
    return radioOptions.map((option: IFieldOption) => {
      option.key = `${option.key}_${this.field.key}`;
      return option;
    });
  }

  private _setValuesInForm(): void {
    const prev = this.formName.value;
    // Save selected values and parse into yyyy/mm/dd format
    prev[this.field.key] = this.selectedDates.map((date: string) => getDateParsed(date, this._numberLocaleFormat));
    this.formName.setValue(prev);
  }
}
