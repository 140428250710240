<form [formGroup]="formName" class="field field__input">
  <mat-label *ngIf="field.label && !field.hideLabel" [ngClass]="validation ? 'field__label' : 'field__labelWarning'">{{
    field.label
  }}</mat-label>
  <textarea
    [style.width]="width + '%'"
    [readonly]="field.disabled"
    [ngClass]="validation ? 'input input--textarea' : 'input input--textarea text--error'"
    [formControlName]="field.key"
    [placeholder]="field.placeholder"
    rows="30"
    (blur)="validator()"
    (input)="validator()"
  ></textarea>
  <mat-hint *ngIf="field.hint" class="field__hint">{{ field.hint }}</mat-hint>
</form>
