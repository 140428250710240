<div class="dialog-container">
  <h3 class="dialog__title" *ngIf="data?.title">{{ data.title }}</h3>
  <p class="dialog__text" *ngIf="data.text">
    {{ data.text }}
  </p>
  <mat-dialog-actions align="start" *ngIf="data.actions" class="dialog-actions">
    <app-button
      *ngIf="data.actions.confirm"
      [buttonConfig]="confirmButton"
      class="dialog__btn btn--confirm"
      [mat-dialog-close]="data.actions.confirm.value"
    >
      {{ data.actions.confirm.text }}
    </app-button>
    <app-button
      *ngIf="data.actions.close"
      [buttonConfig]="cancelButton"
      class="dialog__btn btn--cancel"
      [mat-dialog-close]="data.actions.close.value"
    >
      {{ data.actions.close.text }}
    </app-button>
  </mat-dialog-actions>
</div>
