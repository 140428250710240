import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IArticle } from '../../interfaces/article.interface';
import { environment } from '../../../../environments/environment';
import { getDateParsed } from '../../utils/utils';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() card!: IArticle;
  private readonly _imageURL = environment.directus.assetsUrl;

  public formattedDate: string = '';

  constructor(private _translate: TranslateService) {}

  ngOnInit(): void {
    if (this.card) this._buildCard();
  }

  /**
   * @description this function build a card structure
   */
  private _buildCard(): void {
    if (this.card.header && !this.card.header.id.includes(this._imageURL)) {
      this.card.header.id = `${this._imageURL}${this.card.header.id}`;
    }
    // Format date
    this.formattedDate = this._getCardDates();
    this._setDatesCard(this.formattedDate);
    // Hashtags
    this._setHashtagsListCard();
  }

  private _getDates() {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    return { now, yesterday };
  }

  private _getCardDates(): string {
    const date = new Date(this.card.date_created);
    return this._formatDate(date);
  }

  private _setDatesCard(createdDate: string): void {
    const { now, yesterday } = this._getDates();
    // Get translations for "Today" and "Yesterday" strings
    this._translate.get('TIMELINE.DATES').subscribe((dates) => {
      if (createdDate === this._formatDate(now)) this.formattedDate = dates.TODAY;
      else if (createdDate === this._formatDate(yesterday)) this.formattedDate = dates.YESTERDAY;
      else this.formattedDate = createdDate;
    });
  }

  private _setHashtagsListCard(): void {
    if (this.card.hashtags) {
      this.card.hashtags = this.card.hashtags.map((hashtag: string) => hashtag.replace(' ', '_'));
    }
  }

  private _formatDate(date: Date): string {
    if (isNaN(date.getTime())) return '';

    return getDateParsed(date.toString());
  }
}
