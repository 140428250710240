<div
  *ngIf="formName.controls[key].invalid && (formName.controls[key].dirty || formName.controls[key].touched)"
  class="alert"
>
  <mat-error *ngIf="formName.controls[key].errors!['required']"
    >✖ {{ label }} {{ 'ERRORS.FORM.REQUIRED' | translate }}</mat-error
  >
  <mat-error *ngIf="formName.controls[key].errors!['pattern']"
    >✖ {{ label }} {{ 'ERRORS.FORM.EMAIL' | translate }}</mat-error
  >
  <mat-error *ngIf="formName.controls[key].errors!['match']"
    >✖ {{ label }} {{ 'ERRORS.FORM.EQUALS' | translate }}</mat-error
  >
  <mat-error *ngIf="formName.controls[key].errors!['patternError']"
    >✖ {{ label }}{{ 'ERRORS.FORM.PATTERN' | translate }}</mat-error
  >
</div>
