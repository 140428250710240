const version = 'v1';
export const environment = {
  env: 'dev',
  amplify: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_GeR6FmYgR',
    userPoolWebClientId: '69q89aea98vtnicm2f33fpoe99',
    oauth: {
      scope: ['phone', 'email', 'profile', 'openid', 'attributes'],
      identityDomain: 'auth.dev.heinet.es',
      identityRedirectSignIn: 'https://dev.heinet.es/login/auth-callback',
      identityRedirectSignOut: 'https://dev.heinet.es/login',
      responseType: 'code',
    },
  },
  urlDomains: {
    baseUrl: 'https://api.dev.heinet.es',
    userUrl: '/accounts/' + version + '/users',
    baseUrlImage: 'https://dev.heinet.es',
    logoutUrl: '/logout',
    invoicesUrl: '/invoices/' + version + '/invoices',
    invoicesUpdateTime: '/invoices/' + version + '/sync/info',
    openItemsUrl: '/invoices/' + version + '/invoices/listopenitems',
    prenotificationsUrl: '/invoices/' + version + '/invoices/prenotification',
    ordersUrl: '/customer-srv',
    casesUrl: '/customer-srv/' + version + '/case',
    updateTime: '/customer-srv/' + version + '/sync/info',
    distributorsUrl: '/accounts/' + version + '/distributors',
    alertsUrl: '/notifications/' + version + '/notifications',
  },
  directus: {
    baseUrl: 'https://dmzr2fsgxld57.cloudfront.net/',
    accessToken: 'x3eaT4pwSs4C9RrWRL7IZ2xR2_gnZkw_',
    assetsUrl: 'https://dmzr2fsgxld57.cloudfront.net/assets/',
  },
  tools: {
    ordersPortal: 'https://heinekensp--int.sandbox.my.site.com/services/auth/sso/heinet?startURL=%2F',
    nautilusPortal: 'https://distribuidores.socios-comerciales.com/sapui5/nautilus/index.html#/home',
    hesanetPortal: 'https://portal-ext-desa.es1.heiway.net/irj/portal',
    clavePortal: 'https://www.portalclave.com/',
    fuerzaBarPortal: 'https://www.fuerzabar.es/pedidos-backoffice',
    appTuBar: 'https://www.tu-bar.es/mercadodistribuidores',
    ordenesComerciales: 'https://oh.itrfvl.com',
  },
  googleTagManager: {
    gtmId: 'GTM-WVDNQM8',
  },
};
