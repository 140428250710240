import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

import { IField } from '../../../../interfaces/form.interface';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent {
  @Input() field!: IField;
  @Input() width?: number = 70;
  formName: FormGroup; // Tracks the value and validity state of a group of FormControl instances.
  validation = true;
  public maxLength: number | null = null;

  constructor(private _formgroupDirective: FormGroupDirective) {
    this.formName = this._formgroupDirective.control;
  }

  public checkEmailEqualToConfirmEmail() {
    let prev = this.formName.value;
    // Save selected option
    prev = { ...prev, email: this.formName.value.email! };
    this.formName.setValue(prev);
  }

  public validator() {
    if (this.field.key === 'email') this.checkEmailEqualToConfirmEmail();
    this.validation =
      this.formName.controls[this.field.key].status !== 'INVALID' ||
      (!this.formName.controls[this.field.key].touched && !this.formName.controls[this.field.key].dirty);
  }

  public getMaxLength() {
    if (!this.field || this.maxLength) return null;

    const errors = this.formName.controls[this.field.key].errors;
    if (errors && errors['maxlength']) {
      this.maxLength = errors['maxlength'];
      return errors['maxlength'];
    }
  }

  onPaste(event: ClipboardEvent) {
    if (this.field.key === 'emailConfirm') event.preventDefault(); // Evita el evento de pegar en el campo emailConfirm
  }
}
