<div *ngIf="alerts.length > 0" class="alerts">
  <div class="alerts__header">
    <h3>{{ 'ALERTS.TITLE' | translate }}</h3>
    <div class="header__operations">
      <mat-checkbox
        color="primary"
        (change)="$event ? selectAllAlerts() : null"
        [checked]="allSelected && alerts.length > 0"
      >
        {{ 'ALERTS.SELECT' | translate }}
      </mat-checkbox>
      <div *ngIf="numSelectedAlerts > 0" class="operations__selected">
        <a *ngIf="readPending > 0 && isAnySelectedUnread()" (click)="markSelectedAsRead()" class="link border--right">{{
          'ALERTS.READ' | translate
        }}</a>
        <mat-icon class="pointer" (click)="deleteSelected()" [svgIcon]="'ico-delete'"></mat-icon>
        <a *ngIf="readPending <= 0 || !isAnySelectedUnread()" (click)="deleteSelected()" class="link link--red">{{
          'ALERTS.DELETE' | translate
        }}</a>
      </div>
    </div>
  </div>
  <section class="alerts__content" id="alertSection">
    <div *ngFor="let alert of alerts" [ngClass]="alert.read ? 'alert alert--read' : 'alert alert--pending'">
      <div class="alert__operations">
        <mat-checkbox color="primary" (change)="selectAlert(alert)" [checked]="alert.selected"></mat-checkbox>
        <mat-icon class="pointer" [svgIcon]="'ico-delete'" (click)="deleteAlert(alert)"></mat-icon>
      </div>
      <div class="alert__details">
        <div class="details__main">
          <p [ngClass]="alert.read ? 'main__type main__type--read' : 'main__type main__type--pending'">
            {{ alert.type }}
          </p>
          <span class="date">{{ alert.time }}</span>
        </div>
        <h6>{{ alert.title }}</h6>
        <p>{{ alert.content }}</p>
        <a *ngIf="alert.url && alert.link" class="link" (click)="redirect(alert)"
          ><mat-icon *ngIf="alert.link.external">launch</mat-icon>{{ alert.link.text }}</a
        >
      </div>
    </div>
  </section>
</div>

<div *ngIf="alerts.length <= 0" class="alerts--empty">
  <img class="category__image" src="assets/images/empty-data-img/ico_empty_avisos.svg" />
  <h2>{{ 'ALERTS.EMPTY.TITLE' | translate }}</h2>
  <p>{{ 'ALERTS.EMPTY.SUBTITLE' | translate }}</p>
</div>
