import { Component, ViewChild, Input, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import Cropper from 'cropperjs';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements AfterViewInit {
  @ViewChild('image', { static: false })
  public imageElement!: ElementRef<HTMLImageElement>;

  @Input() src: string = '';
  @Output() imgCaptured = new EventEmitter<any>();

  public imageDestination: string = '';
  private _cropper!: Cropper;

  public ngAfterViewInit() {
    if (this.imageElement && this.imageElement.nativeElement) {
      this._cropper = new Cropper(this.imageElement.nativeElement, {
        zoomable: true,
        scalable: true,
        aspectRatio: 1,
        autoCropArea: 0.01,
        viewMode: 3,
        dragMode: 'move',
        cropBoxResizable: true,
        minCropBoxWidth: 200,
        minCropBoxHeight: 200,
        crop: () => {
          const canvas = this._cropper.getCroppedCanvas();
          this.imageDestination = canvas.toDataURL('image/png');
          this.imgCaptured.emit(this.imageDestination);
        },
      });
    }
  }
}
