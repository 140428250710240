export const DateRangeOptions = [
  {
    key: 'week',
    label: 'Esta semana',
    value: 'week',
  },
  {
    key: 'month',
    label: 'Este mes',
    value: 'month',
  },
  {
    key: 'year',
    label: 'Este año',
    value: 'year',
  },
];
