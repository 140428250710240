import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';

import { environment } from '../../../environments/environment';
import { IAlert } from '../interfaces/alerts.interface';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  private readonly _alertsUrl = `${environment.urlDomains.baseUrl}${environment.urlDomains.alertsUrl}`;
  private _userId = '';

  public $unreadAlerts = new BehaviorSubject<string>('');

  constructor(private _http: HttpClient, private _authService: AuthService) {
    this._userId = this._authService.getUserId();
  }

  public updateUnreadAlerts() {
    this.$unreadAlerts.next('');
  }

  /**
   * getAlerts
   * @description this function return a list for alerts
   */
  public getAlerts(page: number): Observable<IAlert[]> {
    return this._http.get(this._alertsUrl + `?size=5&page=${page}`).pipe(
      map((value: any) => value.data.content),
      catchError(() => throwError(() => 'Error fetching alerts'))
    );
  }

  public readMultipleAlerts(ids: string[]) {
    const url = `${this._alertsUrl}/read`;

    return this._http.patch(url, { list: ids }).pipe(
      map((value: any) => value),
      catchError(() => throwError(() => 'Error setting multiple alerts as read'))
    );
  }

  public readAllAlerts() {
    const url = `${this._alertsUrl}/allNotificationRead`;

    return this._http.patch(url, {}).pipe(
      map((value) => {
        console.log('Todas las notificaciones se marcaron como leídas.');
        return value;
      }),
      catchError(() => throwError(() => 'Ocurrió un error al marcar las notificaciones como leídas'))
    );
  }

  public deleteAlert(id: string) {
    const url = `${this._alertsUrl}/${id}`;

    return this._http.delete(url).pipe(
      map((value: any) => value),
      catchError(() => throwError(() => 'Error deleting alert'))
    );
  }

  public deleteMultipleAlerts(ids: string[]) {
    const url = `${this._alertsUrl}/deleteList`;
    const body = { list: ids };

    return this._http.delete(url, { body }).pipe(
      map((value: any) => value),
      catchError(() => throwError(() => 'Error deleting multiple alerts'))
    );
  }

  public deleteAllAlerts() {
    const url = `${this._alertsUrl}/deleteAllNotification`;

    return this._http.delete(url).pipe(
      map((value) => {
        console.log('El elemento se eliminó correctamente.');
        return value;
      }),
      catchError(() => throwError(() => 'Ocurrió un error al eliminar el elemento'))
    );
  }
  /**
   * Retrieves quantity of unread alerts
   * @returns amount of unread alerts
   */
  public getNumberUnreadAlerts(): Observable<number> {
    const url = `${this._alertsUrl}/not-read-notifications`;
    const params = { userId: this._userId };

    return this._http.get(url, { params }).pipe(
      map((value: any) => value.data),
      catchError(() => throwError(() => 'Error retrieving unread alerts'))
    );
  }
}
