import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, switchMap, throwError } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isResetPWD = window.location.href.includes('reset-password');
    const isLegal = window.location.href.includes('legal/');
    if (isResetPWD) {
      return next.handle(request);
    }

    return this._authService.getIdToken().pipe(
      map((idToken: string) => {
        const token = `Bearer ${idToken}`;
        request = this._setBearerToken(token, request);
        return request;
      }),
      switchMap((newRequest) => next.handle(newRequest)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404 && error.message.includes('invoices/v1/sync/info/invoice: 404 OK')) {
          // Mostrar un mensaje de error al usuario o realizar alguna otra acción
          console.error('Error fetching update time:', error);
          return throwError(() => 'Error fetching update time');
        } else {
          if (isLegal) return next.handle(request);
          this._authService.backToLogin();
          return throwError(() => 'Error getting user token');
        }
      })
      // catchError(() => {
      //   if (isLegal) return next.handle(request);
      //   this._authService.backToLogin();
      //   return throwError(() => 'Error getting user token');
      // })
    );
  }

  private _setBearerToken(token: string, request: HttpRequest<unknown>): HttpRequest<unknown> {
    if (token) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
    }
    return request;
  }
}
