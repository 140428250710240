import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl implements OnDestroy {
  private $unsubscribe: Subject<void> = new Subject<void>();
  private _OF_LABEL = '';
  private _SHOW_LABEL = '';

  constructor(private _translate: TranslateService) {
    super();
    this._translate.get('TABLE').subscribe((text: { ITEMS: string; OF: string; SHOW: string }) => {
      this.itemsPerPageLabel = text.ITEMS;
      this._OF_LABEL = text.OF;
      this._SHOW_LABEL = text.SHOW;
      this._getRangeLabelOnPaginator();
      this.changes.next();
    });
  }

  private _getRangeLabelOnPaginator() {
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 ${this._OF_LABEL} ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = this._calcStartIndex(page, pageSize);
      const endIndex = this._calcEndIdex(startIndex, length, pageSize);
      return this._buildFinalLabel(startIndex, endIndex, length);
    };
  }

  private _calcStartIndex(page: number, pageSize: number): number {
    return page * pageSize;
  }

  private _buildFinalLabel(startIndex: number, endIndex: number, length: number): string {
    return `${this._SHOW_LABEL} ${startIndex + 1} - ${endIndex} ${this._OF_LABEL} ${length}`;
  }

  private _calcEndIdex(startIndex: number, length: number, pageSize: number): number {
    return startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
  }

  ngOnDestroy() {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }
}
