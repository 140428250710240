import { IPermissionsByRol } from '../../interfaces/permissions-by-rol';
import { Roles } from '../enums/roles.model';

export const PermissionsConst: IPermissionsByRol = {
  [Roles.GERENTE]: {
    dashboard: true,
    users_management: true,
    order_service: true,
    invoices: true,
    cases: true,
    profile: true,
    alerts: true,
    timeline: true,
    userManagement: true,
    heineken_universe: true,
  },
  [Roles.FINANZA]: {
    dashboard: true,
    users_management: false,
    order_service: true,
    invoices: true,
    cases: true,
    profile: true,
    alerts: true,
    timeline: true,
    userManagement: false,
  },
  [Roles.LOGISTICA]: {
    users_management: false,
    order_service: true,
    invoices: false,
    cases: true,
    profile: true,
    alerts: true,
    timeline: true,
    userManagement: false,
    heineken_universe: true,
  },
  [Roles.ADMINISTRATIVO]: {
    dashboard: true,
    users_management: false,
    order_service: false,
    invoices: true,
    cases: true,
    profile: true,
    alerts: true,
    timeline: true,
    userManagement: false,
    heineken_universe: true,
  },
  [Roles.ADMINISTRADOR]: {
    dashboard: false,
    users_management: false,
    order_service: false,
    invoices: false,
    cases: false,
    profile: false,
    alerts: false,
    timeline: false,
    userManagement: false,
    heineken_universe: false,
  },
};
