import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/services/auth.service';
import { IDistributor, IDistributorAddress, IProfileData, IProfileDataImage } from '../interfaces/profile.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfileHttpService {
  private readonly _distributorUrl = `${environment.urlDomains.baseUrl}${environment.urlDomains.distributorsUrl}`;
  private readonly _userUrl = `${environment.urlDomains.baseUrl}${environment.urlDomains.userUrl}`;
  private _userId: string;

  constructor(private _http: HttpClient, private _authService: AuthService) {
    this._userId = this._authService.getUserId();
  }

  /**
   * Get user distributor by userId
   */

  public updateImage(image: string): Observable<IProfileDataImage> {
    const url = `${this._userUrl}/uploadImage`;
    return this._http.post(url, image).pipe(
      map((res: any) => res.data),
      catchError(() => throwError(() => 'Error en la petición post'))
    );
  }

  public getProfileData(): Observable<IProfileData> {
    const url = `${this._userUrl}/${this._userId}`;
    return this._http.get(url).pipe(
      map((res: any) => res.data),
      catchError(() => throwError(() => 'Error fetching user attributes'))
    );
  }

  /**
   * Get distributor data by its id
   * @param distributorId id
   * @returns IDistributor data
   */
  public getDistributorById(distributorId: string): Observable<IDistributor> {
    const url = `${this._distributorUrl}/${distributorId}`;
    return this._http.get(url).pipe(
      map((res: any) => res.data),
      catchError(() => throwError(() => `Error fetching distributor '${distributorId}'`))
    );
  }

  public getAddressByIdDistributorId(distributorId: string, page = 0, size = 10): Observable<IDistributorAddress> {
    const url = `${this._distributorUrl}/${distributorId}/address?page=${page}&size=${size}`;

    return this._http.get(url).pipe(
      map((res: any) => res),
      catchError(() => throwError(() => `Error fetching distributor '${distributorId}'`))
    );
  }
}
