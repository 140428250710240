import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { IAttachment } from '../../interfaces/article.interface';
import { ExtensionTypes } from '../enums/attachmentFile.model';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  /**
   * @param fileData data of file want to download
   * @param fileType type of file we want download
   * @param fileName name of file downloaded
   */
  public downloadFile(fileData: BlobPart, fileType: string, fileName?: string): void {
    const blob = new Blob([fileData], { type: `application/${fileType}` });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName ? fileName : 'Invoices_collection';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  /**
   * @param pdfData  data of file want to view in new tab
   */
  public openPDFNewTab(pdfData: BlobPart): void {
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  /**
   * @param fileData Data of csv
   */
  public downloadCSV(fileData: string): void {
    const a = document.createElement('a');
    a.href = 'data:text/csv,' + fileData;
    const filename = 'Facturas_HEINET';
    a.setAttribute('download', filename + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  /**
   * @param imageUrlImage Url of download image
   * @param fileData data from directus about image the user wants download
   */
  public downLoadImage(imageUrlImage: string, fileData: IAttachment) {
    const imageUrl = `${imageUrlImage}/${fileData.filename_download}?download`;
    this._getBase64ImageFromURL(imageUrl).subscribe((base64data: any) => {
      const base64Image = `data:${fileData.type};base64,${base64data}`;

      // save image to disk
      const link = document.createElement('a');

      document.body.appendChild(link); // for Firefox
      const fileNameDownload = this._getFileNameByExtension(fileData);
      link.setAttribute('href', base64Image);

      link.setAttribute('download', fileNameDownload);
      link.click();
      document.body.removeChild(link);
    });
  }

  private _getFileNameByExtension(fileData: IAttachment): string {
    if (!this._isSVGImage(fileData.type)) return fileData.filename_download;
    return fileData.filename_download.replace(ExtensionTypes.SVG, ExtensionTypes.JPG);
  }

  private _isSVGImage(type: string): boolean {
    return type === 'image/svg+xml';
  }

  private _getBase64ImageFromURL(url: string) {
    return new Observable((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Heinet';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this._getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this._getBase64Image(img));
        observer.complete();
      }
    });
  }

  private _getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL('image/png');

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }
}
