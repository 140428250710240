import { Component, Input } from '@angular/core';
import { OpenCaseFormService } from '../../services/create-case-form.service';
import { typeOfCaseToNavigate } from '../../utils/mocks/cases.mocks';
import { MediaScreen } from '../../utils/const/media_screen-const';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() showFields: boolean = true;
  public readonly contactNumber = '900 838 668';
  private readonly _mediaScreen = MediaScreen.MOBILE;

  constructor(private _openCaseForm: OpenCaseFormService) {}

  public navigateCreateCase(): void {
    const navigateKey = Object.keys(typeOfCaseToNavigate)[1];
    this._openCaseForm.openCreateCaseForm(navigateKey);
  }

  public openCookiesPreferences() {
    const screenWidth = window.innerWidth;
    const cookiesPref = document.getElementById('ensModalWrapper');
    if (cookiesPref) {
      cookiesPref.style.display = 'block';
      cookiesPref.style.top = '15%';
      cookiesPref.style.left = screenWidth > this._mediaScreen ? '35%' : '0';
    }
  }
}
