import { gridKeys } from '../enums/gridKeys.model';

export const MediaScreen = {
  MOBILE: 480,
  TABLET: 768,
  DESKTOP: 1440,
};

export interface RowSizes {
  [key: string]: {
    [key: number]: number;
  };
}

export const rowSizes: RowSizes = {
  [gridKeys.ORDERS]: {
    0: 34,
    1: 46,
    2: 81,
    3: 117,
    4: 152,
    5: 188,
  },
  [gridKeys.CASES]: {
    0: 34,
    1: 34,
    2: 58,
    3: 80,
    4: 104,
    5: 128,
  },
  [gridKeys.INVOICES]: {
    0: 34,
    1: 34,
    2: 62,
    3: 88,
    4: 116,
    5: 144,
  },
};
